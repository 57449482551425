import { AdminActionTypes } from "./admin.types";

const INITIAL_STATE = {
  isNewMasterUploaded: false,
  isNewProductUploaded: false,
  isNewUserAdded: false,
  isUserImpersonated: false,
  impersonatedUser: "",
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminActionTypes.UPLOAD_MASTER_PASS:
      return {
        ...state,
        isNewMasterUploaded: true,
      };
    case AdminActionTypes.UPLOAD_MASTER_FAIL:
      return {
        ...state,
        isNewMasterUploaded: false,
      };
    case AdminActionTypes.ADD_NEW_USER_PASS:
      return {
        ...state,
        isNewUserAdded: true,
      };
    case AdminActionTypes.ADD_NEW_USER_FAIL:
      return {
        ...state,
        isNewUserAdded: false,
      };
    case AdminActionTypes.IMPERSONATE_USER_PASS:
      return {
        ...state,
        isUserImpersonated: true,
        impersonatedUser: action.payload,
      };
    case AdminActionTypes.IMPERSONATE_USER_FAIL:
      return {
        ...state,
        isUserImpersonated: false,
        impersonatedUser: "",
      };
    case AdminActionTypes.UPLOAD_PRODUCT_PASS:
      return {
        ...state,
        isNewProductUploaded: true,
      };
    case AdminActionTypes.UPLOAD_PRODUCT_FAIL:
      return {
        ...state,
        isNewProductUploaded: false,
      };
    case AdminActionTypes.MASTER_CONTRACT_READY:
      return {
        ...state,
        isNewMasterUploaded: false,
      };
    case AdminActionTypes.ADD_NEW_USER_READY:
      return {
        ...state,
        isNewUserAdded: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default adminReducer;
//     case MasterActionTypes.SET_BACK_TO_CATEGORY:
//       return {
//         ...state,
//         displayType: "categories",
//         selectedCategory: null,
//       }
// //     case MasterActionTypes.SET_BACK_TO_SUBJECT:
// //       return {
// //         ...state,
// //         displayType: "subjects",
// //         selectedSubject: null,
// //       }
// //     case MasterActionTypes.SET_SELECTED_CATEGORY_PASS:
// //       return {
//         ...state,
//         displayType:'subjects',
//         selectedOption:null,
//         selectedCategory: action.payload.selected,
//         category:  action.payload.category,
//       };
//     case MasterActionTypes.SET_SELECTED_SUBJECT_PASS:
//         return {
//           ...state,
//           displayType:'options',
//           selectedSubject: state.category.subjects.find(el=>el._id===action.payload._id)
//         };
//     case MasterActionTypes.CREATE_CATEGORY_PASS:
//       return {
//         ...state,
//         category: action.payload,
//       };
//     case MasterActionTypes.CREATE_CATALOG_PASS:
//     case MasterActionTypes.LOAD_CATALOG_PASS:
//       return {
//         ...state,
//         displayType:'categories',
//         catalog: action.payload,
//       };
//     case MasterActionTypes.SET_CATEGORY_PASS:
//       return {
//         ...state,
//         displayType: action.payload.displayType,
//         category: action.payload.category,
//       };
//     case MasterActionTypes.SET_SUBJECT_PASS:
//       return {
//         ...state,
//         displayType: action.payload.displayType,
//         subject: action.payload.subject,
//       };
//     case MasterActionTypes.SET_OPTION_PASS:
//       return {
//         ...state,
//         displayType: action.payload.displayType,
//       };
//     case MasterActionTypes.CREATE_CATALOG_FAIL:
//     case MasterActionTypes.LOAD_CATALOG_FAIL:
//       return {
//         ...state,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// }
