import axios from "axios";
import { ContractActionTypes } from "./contract.types";
import { convertFromDb } from "./contract-helper.actions";

const contractsURL = "/api/v1/contracts/";
//const config = { headers: { "Content-Type": "application/json" } };
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

///////////////////////////////////////////////////////////////
// When a user logs in
//////////////////////////////////////////////////////////////
export const getMyContractNames = (auth) => async (dispatch) => {
  try {
    let conf = {
      headers: { "Content-Type": "application/json" },
      withCredentials: auth,
    };
    let res =
      auth === true
        ? await axios.get(contractsURL, config)
        : await axios.get("/api/v1/contracts/guest", conf);
    return dispatch({
      type: ContractActionTypes.LOAD_CONTRACT_NAMES_PASS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.LOAD_CONTRACT_NAMES_FAIL,
    });
  }
};

//////////////////////////////////////////////////////////////
// Change contract names from Edit Name button the dashboard
//////////////////////////////////////////////////////////////
export const editContractName = (id) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.EDIT_CONTRACT_NAME,
    payload: id,
  });
};
export const editContractNameInput = (pay) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.EDIT_CONTRACT_NAME_INPUT,
    payload: pay,
  });
};
export const cancelContractName = (id) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.CANCEL_CONTRACT_NAME,
    payload: id,
  });
};
export const saveContractName = (con) => async (dispatch) => {
  const { _id, editedTempName, isSubscriber } = con;
  try {
    let res;
    if (isSubscriber) {
      const tempRes = await axios.patch(
        `${contractsURL}${_id}/name`,
        { contractName: editedTempName },
        config
      );
      res = tempRes.data.data;
    } else res = [{ _id: _id, contractName: editedTempName }];
    return dispatch({
      type: ContractActionTypes.SAVE_CONTRACT_NAME_PASS,
      payload: res,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.SAVE_CONTRACT_NAME_FAIL,
    });
  }
};

/////////////////////////////////////////////////////////////////
/// Reset Contract button on dashboard
/////////////////////////////////////////////////////////////////
export const getTemplateNames = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/contracts/templates/", config);
    return dispatch({
      type: ContractActionTypes.GET_TEMPLATE_NAMES_PASS,
      payload: res.data.data,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.GET_TEMPLATE_NAMES_FAIL,
    });
  }
};
export const resetToTemplate =
  ({ templateId, contractId }) =>
  async (dispatch) => {
    try {
      await axios.patch(
        `${contractsURL}${contractId}/template`,
        { templateId: templateId },
        config
      );

      return dispatch({
        type: ContractActionTypes.RESET_TEMPLATE_NAME_PASS,
      });
    } catch (err) {
      return dispatch({
        type: ContractActionTypes.RESET_TEMPLATE_NAME_FAIL,
      });
    }
  };

// export const resetContract = (id) => async (dispatch) => {
//   try {
//     const res = await axios.patch(`${contractsURL}${id}/reset`, config);
//     return dispatch({
//       type: ContractActionTypes.RESET_CONTRACT_PASS,
//       payload: res.data.data,
//     });
//   } catch (err) {
//     return dispatch({
//       type: ContractActionTypes.RESET_CONTRACT_FAIL,
//     });
//   }
// };

export const updateToLatestRevision = (id) => async (dispatch) => {
  try {
    let res = await axios.get(`/api/v1/contracts/${id}/latest`, config);

    return dispatch({
      type: ContractActionTypes.LATEST_CONTRACT_PASS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.LATEST_CONTRACT_FAIL,
    });
  }
};

//////////////////////////////////////////////////////////
// These all come from Edit button on the dashboard
//////////////////////////////////////////////////////////
export const loadContract = (data, auth) => async (dispatch) => {
  try {
    const { _id, contractName } = data;

    let conf = {
      headers: { "Content-Type": "application/json" },
      withCredentials: auth,
    };
    let res =
      auth === true
        ? await axios.get(`${contractsURL}${_id}`, config)
        : await axios.get(`/api/v1/contracts/guest/${_id}`, conf);

    // const res = await axios.get(`${contractsURL}${id}`, config);
    const converted = convertFromDb(res.data.data);
    if (auth === false) converted["contractName"] = contractName;
    return dispatch({
      type: ContractActionTypes.LOAD_CONTRACT_PASS,
      payload: { converted, auth },
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.LOAD_CONTRACT_FAIL,
    });
  }
};

export const saveContract = (con) => async (dispatch) => {
  let id = con._id;
  let cont = { ...con };
  delete cont["_id"];

  try {
    await axios.patch(`${contractsURL}${id}`, cont, config);
    return dispatch({
      type: ContractActionTypes.SAVE_CONTRACT_PASS,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.SAVE_CONTRACT_FAIL,
    });
  }
};
/////////////////////////////////////////////////////////////

export const setSelectedCategory = (id) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_SELECTED_CATEGORY_PASS,
    payload: id,
  });
};

export const invertCheckedSubject = (id) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.INVERT_CHECKED_SUBJECT_PASS,
    payload: id,
  });
};
export const setEditSubject = (id) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_EDIT_SUBJECT_PASS,
    payload: id,
  });
};
export const setSaveSubject = (id) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_SAVE_SUBJECT_PASS,
    payload: id,
  });
};
export const setCancelSubject = (id) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_CANCEL_SUBJECT_PASS,
    payload: id,
  });
};
export const setEditSubjectInput = (pay) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_EDIT_SUBJECT_INPUT,
    payload: pay,
  });
};

export const invertCheckedOption = (pay) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.INVERT_CHECKED_OPTION_PASS,
    payload: pay,
  });
};
export const setEditOption = (pay) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_EDIT_OPTION_PASS,
    payload: pay,
  });
};
export const setSaveOption = (pay) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_SAVE_OPTION_PASS,
    payload: pay,
  });
};
export const setCancelOption = (pay) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_CANCEL_OPTION_PASS,
    payload: pay,
  });
};
export const setEditOptionInput = (pay) => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SET_EDIT_OPTION_INPUT,
    payload: pay,
  });
};

export const createContract = () => async (dispatch) => {
  try {
    const res = await axios.post(contractsURL, config);
    return dispatch({
      type: ContractActionTypes.CREATE_CONTRACT_PASS,
      payload: res.data.data,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.CREATE_CONTRACT_FAIL,
    });
  }
};

export const deleteContract = (id) => async (dispatch) => {
  try {
    await axios.delete(`${contractsURL}${id}`, config);
    return dispatch({
      type: ContractActionTypes.DELETE_CONTRACT_PASS,
      payload: id,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.DELETE_CONTRACT_FAIL,
    });
  }
};
export const makePdfFile = (cont) => async (dispatch) => {
  try {
    let res;
    cont.subscriber === true
      ? (res = await axios.post(`${contractsURL}pdfCreator`, cont, config))
      : (res = await axios.post(`${contractsURL}pdf-guest`, cont, config));
    return dispatch({
      type: ContractActionTypes.MAKE_PDF_FILE_PASS,
      payload: res.data.data,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.MAKE_PDF_FILE_FAIL,
    });
  }
};
export const unloadContracts = () => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.LOGOUT_UNLOAD_CONTRACTS,
  });
};

//////////////////////////////////////////////////////////
// These are for the Guests
//////////////////////////////////////////////////////////
export const loadGuestContract = () => async (dispatch) => {
  try {
    const res = await axios.get(`${contractsURL}guest`, config);
    return dispatch({
      type: ContractActionTypes.LOAD_GUEST_CONTRACT_PASS,
      payload: res.data.data,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.LOAD_GUEST_CONTRACT_FAIL,
    });
  }
};
export const clearContract = () => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.CLEAR_CONTRACT_PASS,
  });
};


/////////////////////////////////////////////////////////////////////////
///   May not need
/////////////////////////////////////////////////////////////////////////
export const testSelectAllItemsInContract = () => async (dispatch) => {
  return dispatch({
    type: ContractActionTypes.SELECT_ALL_ITEMS_IN_CONTRACT,
  });
};
export const testLoadContract = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${contractsURL}${id}`, config);
    return dispatch({
      type: ContractActionTypes.TEST_LOAD_CONTRACT_PASS,
      payload: res.data.data,
    });
  } catch (err) {
    return dispatch({
      type: ContractActionTypes.LOAD_CONTRACT_FAIL,
    });
  }
};
