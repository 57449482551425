import React, { Fragment } from "react";
import { Card } from "primereact/card";
import { CONTAINER } from "../../assets/styles/containers.syles";

const RegisterPass = () => {
  return (
    <Fragment>
      <div className='bg-image'>
        <div className='p-shadow-12'>
          <CONTAINER width='30%'>
            <div className='p-shadow-12 p-d-block p-mx-auto'>
              <Card
                title='Your Registration is Almost Complete'
                className='bg-manilla'
              >
                <h4>Please check your email to confirm your account.</h4>
                <h4>If you did not receive an email, check your Spam folder</h4>
                <h4 className='p-text-center'>You can contact us at</h4>
                <h5 className='p-text-center'>
                  administrator@chessthinker.com
                </h5>
              </Card>
            </div>
          </CONTAINER>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterPass;
