export const UserActionTypes = {
  REGISTRATION_CONFIRM_PASS: "REGISTRATION_CONFIRM_PASS", // YES
  REGISTRATION_CONFIRM_FAIL: "REGISTRATION_CONFIRM_FAIL", // YES
  LOGIN_SUCCESS: "LOGIN_SUCCESS", // YES
  LOGIN_NO_EMAIL: "LOGIN_NO_EMAIL", // YES Credentials are good email is not validated yet
  LOGIN_FAIL: "LOGIN_FAIL", // YES
  USER_LOADED: "USER_LOADED", // YES This logs in the user and sets the user informaton
  LOGOUT: "LOGOUT",
  AUTH_ERROR: "AUTH_ERROR", // YES The user failed to log in. This shouldn't happen
  CLEAR_ERRORS: "CLEAR_ERRORS",
  FORGOT_PASSWORD_PASS: "FORGOT_PASSWORD_PASS",
  FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",
  RESET_PASSWORD_PASS: "RESET_PASSWORD_PASS",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
  SET_PASSWORD_PASS: "SET_PASSWORD_PASS",
  SET_PASSWORD_FAIL: "SET_PASSWORD_FAIL",
  CHANGE_PASSWORD_PASS: "CHANGE_PASSWORD_PASS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
  RESET_PASSWORD_JUST_CHANGED: "RESET_PASSWORD_JUST_CHANGED",
  // REGISTER_SUCCESS: "REGISTER_SUCCESS", //YES
  // REGISTER_FAIL: "REGISTER_FAIL", //Yes
  // LOGIN_SUCCESS: "LOGIN_SUCCESS", //YES
  // LOGIN_FAIL: "LOGIN_FAIL", //YES
  // USER_LOADED: "USER_LOADED", //YES
  // LOGOUT: "LOGOUT", //YES
  // AUTH_ERROR: "AUTH_ERROR", //YES
  // CLEAR_ERRORS: "CLEAR_ERRORS", //YES
  // FORGOT_PASSWORD_PASS: "FORGOT_PASSWORD_PASS",
  // FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",
  // RESET_PASSWORD_PASS: "RESET_PASSWORD_PASS",
  // RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
  // CHANGE_PASSWORD_PASS: "CHANGE_PASSWORD_PASS",
  // CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
  // RESET_PASSWORD_JUST_CHANGED: "RESET_PASSWORD_JUST_CHANGED",
  // REGISTRATION_SENT_PASS: "REGISTRATION_SENT_PASS",
  // REGISTRATION_SENT_FAIL: "REGISTRATION_SENT_FAIL",
  // REGISTRATION_CONFIRM_PASS: "REGISTRATION_CONFIRM_PASS",
  // REGISTRATION_CONFIRM_FAIL: "REGISTRATION_CONFIRM_FAIL",
};
