import React, { Fragment } from "react";
import ForgotPassword from "../../components/forgot-password/forgot-password.component";

const ForgotPasswordPage = () => {
  return (
    <Fragment>
      <div className='bg-image'>
        <ForgotPassword />
      </div>
    </Fragment>
  );
};

export default ForgotPasswordPage;
