import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Message } from "primereact/message";
import { CONTAINER } from "../../assets/styles/containers.syles";

import { setPassword } from "../../redux/user/user.actions";

const AcceptInvite = ({ user, history, token, setPassword }) => {
  const [message, setMessage] = useState("");
  const [passwordIn, setPasswordIn] = useState({
    password: "",
    passwordConfirm: "",
  });
  useEffect(() => {
    if (user.isResetPasswordSent === 1) history.push("/login/changed");
    if (user.isResetPasswordSent === -1) history.push("/login/notchanged");
    // eslint-disable-next-line
  }, [user.isResetPasswordSent]);
  const { password, passwordConfirm } = passwordIn;

  const handleResetPassword = async (e) => {
    if (passwordIn.password !== passwordIn.passwordConfirm) {
      setMessage("Passwords do not match");
      return;
    }
    if (
      passwordIn.password.length < 8 ||
      passwordIn.passwordConfirm.length < 8
    ) {
      setMessage("Password or confirm password is too short");
      return;
    }
    setMessage("");
    await setPassword({ passwordIn, token });
  };

  const handleInputChange = (e) => {
    setPasswordIn({ ...passwordIn, [e.target.name]: e.target.value });
  };
  return (
    <CONTAINER width='30%'>
      <div className='p-shadow-12 p-d-block p-mx-auto'>
        <Card title='Reset Password' className='bg-manilla'>
          <div className='p-grid p-dir-col'>
            <div className='p-field p-fluid'>
              <label htmlFor='password-4'>Password</label>
              <Password
                id='password-4'
                name='password'
                value={password}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>

            <div className='p-field p-fluid'>
              <label htmlFor='password-confirm-4'>Confirm Password</label>
              <Password
                id='passwordConfirm-4'
                name='passwordConfirm'
                value={passwordConfirm}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>

            <div className='p-d-flex p-jc-evenly'>
              <Button
                label='Set Password'
                disabled={false}
                onClick={handleResetPassword}
              />
            </div>
            <div className='p-d-flex p-jc-evenly'>
              {message.length > 2 && (
                <Message severity='error' text={message} />
              )}
              {user.isResetPassword === -1 && (
                <Message severity='error' text='Password was not set' />
              )}
            </div>
          </div>
        </Card>
      </div>
    </CONTAINER>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { setPassword })(AcceptInvite)
);
