import React, { Fragment, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import AddUser from "./add-user.component";
import { impersonateUser } from "../../redux/admin/admin.actions";
import { impersonateLoginUser } from "../../redux/user/user.actions";

const ShowUsers = ({
  history,
  admin,
  impersonateUser,
  impersonateLoginUser,
}) => {
  const [users, setUsers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [admin.isNewUserAdded]);

  const getAllUsers = async (e) => {
    try {
      const res = await axios.get("/api/v1/users", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setUsers(res.data.data);
    } catch (err) {}
  };

  const header = (
    <div className='p-d-flex p-jc-between'>
      <p className='m-0 fontl'>Users</p>
      <AddUser />
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  const handleImpersonate = async (rowData) => {
    await impersonateLoginUser({ email: rowData.email });
    await impersonateUser(rowData._id);
    history.push("/im/home");
  };

  const impersonateButton = (rowData) => {
    return (
      <div>
        <Button
          className='p-button-warn p-button-sm'
          label='Impersonate'
          onClick={() => handleImpersonate(rowData)}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <div className='p-shadow-12'>
        <Card className='small-table'>
          <DataTable
            value={users}
            dataKey='_id'
            paginator={users.length > 10}
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            header={header}
            globalFilter={globalFilter}
          >
            <Column
              field='email'
              header='Email'
              sortable={true}
              style={{ width: "50%" }}
            />
            <Column
              field='role'
              header='Role'
              sortable={true}
              style={{ width: "20%" }}
            />
            <Column body={impersonateButton} />
            {/* <Column field='_id' header='Database ID' sortable={true} /> */}
          </DataTable>
        </Card>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});
export default withRouter(
  connect(mapStateToProps, { impersonateUser, impersonateLoginUser })(ShowUsers)
);
