class TestHelper {
  static selectAllItems = (state) => {
    const res = { ...state };
    res.contract.categories.map((category) => {
      category.subjects.map((subject) => {
        subject.isSelected = true;
        subject.options.map((option) => {
          option.isSelected = true;
          return true;
        });
        return true;
      });
      return true;
    });
    res.isContractEdited = true;
    return { ...res };
  };
}

export default TestHelper;
