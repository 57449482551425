import React, { Fragment } from "react";

import PdfViewer from "../../components/pdf-viewer/pdf-viewer.component";

const MyPdfViewPage = () => {
  return (
    <Fragment>
      <div className='bg-image'>
        <div className='p-shadow-12'>
          <PdfViewer />
        </div>
      </div>
    </Fragment>
  );
};

export default MyPdfViewPage;
