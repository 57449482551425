import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { CONTAINER_DIV } from "../../assets/styles/containers.syles";
import { withRouter } from "react-router-dom";

const Trial = ({ history }) => {
  const handleStartTrial = () => {
    history.push("/guest/home");
  };
  return (
    <CONTAINER_DIV>
      <div className='p-grid'>
        <div className='p-col-6 p-offset-3'>
          <Card className='p-m-2 p-p-3 bg-manilla'>
            <h1 className='p-text-center mt-0 pb-1p5'>
              Free Trial Information
            </h1>
            <h4>
              The Free Trial has the functionality of the complete program with
              four exceptions.
            </h4>
            <ol>
              <li>
                <h5>
                  In the complete program you can create and maintain five
                  personalized Partnership Agreements whereas in the Free Trial
                  you can create only one.
                </h5>
              </li>
              <li>
                <h5>
                  In the complete program you have access to 13 Categories of
                  agreements whereas in the Free Trial you have access to only
                  two Categories (i) Overcalls and (ii) Doubles and Redoubles.
                </h5>
              </li>
              <li>
                <h5>
                  In the complete program you can save changes to the
                  personalized Partnership Agreements for later use and
                  modification whereas in the Free Trial your personalized
                  Partnership Agreement can not be saved.
                </h5>
              </li>
              <li>
                <h5>
                  In the complete program you can start (or reset) your
                  Partnership Agreements with any of 6 templates whereas in the
                  Free Trial you can start with only a blank Partnership
                  Agreement and you can not reset it.
                </h5>
              </li>
            </ol>
            <Button
              className='p-mt-4 p-mx-auto p-d-block'
              label='Get Started'
              onClick={handleStartTrial}
            />
          </Card>
        </div>
      </div>
    </CONTAINER_DIV>
  );
};

export default withRouter(Trial);
