import React, { Fragment, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Menu } from "primereact/menu";
import { Menubar } from "primereact/menubar";

import { CONTAINER_DIV } from "../../assets/styles/containers.syles";
import { logoutUser } from "../../redux/user/user.actions";
import { unloadContracts } from "../../redux/contract/contract.actions";
import logo from "../../assets/images/default.jpg";

const Navbar = ({ user, contract, history, location }) => {
  const logoutHandle = {
    label: "Logout",
    command: async () => {
      history.push("/");
    },
  };

  const instructionsHandle = {
    label: "Instructions",
    command: () => {
      history.push("/my/instructions");
    },
  };

  const dashboardHandle = {
    label: "Go To Dashboard",
    command: () => {
      history.push("/my/home");
    },
  };

  const contractHandle = {
    label: "Go To Agreement Editor",
    command: () => {
      history.push("/my/contract");
    },
  };
  const contactHandle = {
    label: "Contact Us",
    command: () => {
      history.push("/my/contact");
    },
  };
  const adminHandle = {
    label: "Admin Panel",
    command: () => {
      history.push("/my/admin");
    },
  };
  const guestHomeHandle = {
    label: "Home",
    command: () => {
      history.push("/");
      // window.location.href = "https://www.mybridgecontracts.com";
    },
  };
  const guestDashboardHandle = {
    label: "Go To Dashboard",
    command: () => {
      history.push("/guest/home");
    },
  };
  const guestInstructionsHandle = {
    label: "Instructions",
    command: () => {
      history.push("/instructions");
      // window.location.href = "https://www.mybridgecontracts.com/instructions";
    },
  };
  const guestTrialHandle = {
    label: "Free Trial",
    command: () => {
      history.push("/trial");
    },
  };
  const guestContactHandle = {
    label: "Contact Us",
    command: () => {
      history.push("/contact");
    },
  };
  const guestLoginHandle = {
    label: "Sign In",
    command: () => {
      history.push("/login");
    },
  };
  const guestRegisterHandle = {
    label: "Sign Up",
    className: "stand-out",
    command: () => {
      history.push("/register");
    },
  };

  const dashboardPageItems = [instructionsHandle, contactHandle, logoutHandle];
  const contractPageItems = [
    dashboardHandle,
    instructionsHandle,
    contactHandle,
    logoutHandle,
  ];
  const instructionsPageItems = [dashboardHandle, contractHandle, logoutHandle];
  const instructionsNoContractPageItems = [dashboardHandle, logoutHandle];
  const contactPageItems = [
    dashboardHandle,
    contractHandle,
    instructionsHandle,
    logoutHandle,
  ];
  const adminPageItems = [
    dashboardHandle,
    instructionsHandle,
    logoutHandle,
    adminHandle,
  ];
  const guestHomeItems = [
    guestLoginHandle,
    guestRegisterHandle,
    guestContactHandle,
  ];
  const guestTrialItems = [
    guestHomeHandle,
    guestInstructionsHandle,
    guestContactHandle,
  ];

  const guestDashboardItems = [
    guestHomeHandle,
    guestInstructionsHandle,
    guestContactHandle,
    // guestLogoutHandle,
  ];
  const guestContractItems = [
    guestDashboardHandle,
    guestInstructionsHandle,
    guestContactHandle,
  ];

  const guestInstructionsItems = [
    guestHomeHandle,
    guestTrialHandle,
    guestLoginHandle,
    guestRegisterHandle,
  ];
  const guestLoginItems = [
    guestHomeHandle,
    guestInstructionsHandle,
    guestTrialHandle,
    guestContactHandle,
    guestRegisterHandle,
  ];
  const guestRegisterItems = [
    guestHomeHandle,
    guestInstructionsHandle,
    guestTrialHandle,
    guestContactHandle,
    guestLoginHandle,
  ];
  const guestPageItems = [
    guestHomeHandle,
    guestInstructionsHandle,
    guestTrialHandle,
    guestContactHandle,
    guestLoginHandle,
    guestRegisterHandle,
  ];

  const [navItems, setNavItems] = useState(guestPageItems);
  useEffect(() => {
    setNavType();
    // eslint-disable-next-line
  }, [user, location]);

  const setNavType = () => {
    if (user.isLoggedIn === false) {
      if (location.pathname === "/") return setNavItems(guestHomeItems);
      if (location.pathname === "/trial") return setNavItems(guestTrialItems);
      if (location.pathname === "/instructions")
        return setNavItems(guestInstructionsItems);
      if (location.pathname === "/login") return setNavItems(guestLoginItems);
      if (location.pathname === "/register")
        return setNavItems(guestRegisterItems);
      if (location.pathname === "/guest/home")
        return setNavItems(guestDashboardItems);
      if (location.pathname === "/guest/contract")
        return setNavItems(guestContractItems);
      return setNavItems(guestPageItems);
    }
    if (user.role === "admin") return setNavItems(adminPageItems);
    if (location.pathname === "/my/home")
      return setNavItems(dashboardPageItems);
    if (location.pathname === "/my/contact")
      return setNavItems(contactPageItems);
    if (
      location.pathname === "/my/pdfview" ||
      location.pathname === "/my/contract"
    )
      return setNavItems(contractPageItems);
    if (contract.contract !== null && location.pathname === "/my/instructions")
      return setNavItems(instructionsPageItems);
    if (contract.contract === null && location.pathname === "/my/instructions")
      return setNavItems(instructionsNoContractPageItems);

    return setNavItems(guestPageItems);
  };

  // const start = <h5 className='mt-1r'>My Bridge Contracts</h5>;
  const start = user.isLoggedIn ? (
    <Link to='/my/home'>
      <img alt='logo' src={logo} height='60' className='p-mr-2'></img>
    </Link>
  ) : (
    <Link to='/'>
      <img alt='logo' src={logo} height='60' className='p-mr-2'></img>
    </Link>
  );

  return (
    <Fragment>
      {location.pathname === "/" ? (
        <div className='navbar-right'>
          <Menu model={navItems} start={start} />
        </div>
      ) : (
        <div className='navbar-notcontained'>
          <CONTAINER_DIV>
            <div className='navbar-container'>
              <Menubar model={navItems} start={start} />
            </div>
          </CONTAINER_DIV>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  contract: state.contract,
});

export default withRouter(
  connect(mapStateToProps, { unloadContracts, logoutUser })(Navbar)
);
