import React from "react";
import ResetPassword from "../../components/reset-password/reset-password.component";

const ResetPasswordPage = ({
  match: {
    params: { tok },
  },
}) => {
  return (
    <div className='bg-image'>
      <ResetPassword token={tok} />
    </div>
  );
};

export default ResetPasswordPage;
