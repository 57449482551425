import React, { useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import axios from "axios";

const PdfViewer = ({ contract, history }) => {
  // const [pdfFileUrl, setPdfFileUrl] = useState("");
  let development = "c7d1bab9c6ea4f53b1771afe52a0e798";
  let info = development;

  useEffect(() => {
    viewPdfFile();
    // eslint-disable-next-line
  }, []);

  const handleBack = () =>
    contract.isSubscriber
      ? history.push("/my/contract")
      : history.push("/trial");

  const previewConfig = {};

  const viewPdfFile = async () => {
    if (
      contract.pdfFileInfo === null ||
      contract.pdfFileInfo.fileName === undefined
    )
      return;
    try {
      let res = await axios(
        `/api/v1/contracts/pdfviewer/${contract.pdfFileInfo.fileName}`,
        {
          method: "GET",
          responseType: "blob",
        }
      );

      let blob = await new Blob([res.data], {
        type: "application/pdf",
      });

      const fileURL = await URL.createObjectURL(blob);

      // setPdfFileUrl(fileURL);
      let adobeDCView = new window.AdobeDC.View({
        clientId: info,
        divId: "adobe-dc-view",
      });
      let previewFilePromise = adobeDCView.previewFile(
        {
          content: { location: { url: fileURL } },
          metaData: { fileName: `${contract.pdfFileInfo.fileName}` },
          enableLinearization: true,
        },
        previewConfig
      );
      previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          // All viewer APIs can be invoked here
          apis
            .getPDFMetadata()
            .then((result) => console.log(result))
            .catch((error) => console.log(error));
        });
      });
    } catch (error) {
      console.log("An error occured", error);
    }
  };
  // embedMode options are: Full Window(default),SIZED_CONTAINER, IN_LINE, LIGHT_BOX
  return (
    <Fragment>
      <div className='p-shadow-12'>
        <Card className='bg-image'>
          <div className='p-grid'>
            <div className='p-col-4'>
              <div className='p-card-title'>
                {contract.contract === null
                  ? "Please Load a Contract"
                  : contract.contract.contractName === undefined
                  ? `${contract.contract._id}`
                  : `Contract Name: ${contract.contract.contractName}`}
              </div>
            </div>
            <div className='p-col-2 p-offset-1'>
              <Button
                label='Go To Contract Editor'
                className='p-text-center '
                onClick={handleBack}
              ></Button>
            </div>
          </div>
          {contract.contract !== null && <div id='adobe-dc-view'></div>}
        </Card>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  contract: state.contract,
});
export default withRouter(connect(mapStateToProps, null)(PdfViewer));
