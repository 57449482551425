import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import axios from "axios";

const PdfViewer2 = ({ contract, history }) => {
  const [pdfFileUrl, setPdfFileUrl] = useState("");

  useEffect(() => {
    viewPdfFile();
    // eslint-disable-next-line
  }, []);

  const handleBack = () =>
    contract.isSubscriber
      ? history.push("/my/contract")
      : history.push("/trial");

  const viewPdfFile = async () => {
    if (
      contract.pdfFileInfo === null ||
      contract.pdfFileInfo.fileName === undefined
    )
      return;
    try {
      let res = await axios(
        `/api/v1/contracts/pdfviewer/${contract.pdfFileInfo.fileName}`,
        {
          method: "GET",
          responseType: "blob",
        }
      );

      let blob = await new Blob([res.data], {
        type: "application/pdf",
      });
      //  let a = document.createElement("a");
      //  a.style = "display: none";
      //  document.body.appendChild(a);

      const fileURL = await URL.createObjectURL(blob);

      setPdfFileUrl(fileURL);
    } catch (error) {
      console.log("An error occured", error);
    }
  };

  return (
    <Fragment>
      <div className='p-shadow-12'>
        <Card className='bg-image'>
          <div className='p-grid'>
            <div className='p-col-4'>
              <div className='p-card-title'>
                {contract.contract === null
                  ? "Please Load a Contract"
                  : contract.contract.contractName === undefined
                  ? `${contract.contract._id}`
                  : `Contract Name: ${contract.contract.contractName}`}
              </div>
            </div>
            <div className='p-col-2 p-offset-1'>
              <Button
                label='Go To Contract Editor'
                className='p-text-center '
                onClick={handleBack}
              ></Button>
            </div>
          </div>
          {contract.contract !== null && (
            <div className='iframe-container'>
              <iframe
                src={`${pdfFileUrl}#toolbar=1&thubmnail=0&view=Fit`}
                title='resumeFrame'
                width='1000'
                height='500'
                allowFullScreen=''
              ></iframe>
            </div>
          )}
        </Card>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  contract: state.contract,
});
export default withRouter(connect(mapStateToProps, null)(PdfViewer2));
