import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../redux/user/user.actions";
import { unloadContracts } from "../../redux/contract/contract.actions";

import { HOME_CONTAINER_DIV } from "../../assets/styles/containers.syles";

const HomePage = ({ history, unloadContracts, logoutUser }) => {
  const initialize = async () => {
    await logoutUser();
    await unloadContracts();
  };
  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className='bg-image'>
        <div className='h-50' />
        <HOME_CONTAINER_DIV className='bg-manilla'>
          <div className='p-4'>
            <h1 className='p-text-center mt-0 pb-1p5'>My Bridge Contracts</h1>

            <h2 className='p-my-5'>
              Looking for an easy-to-read document that has the details of your
              bridge partnership agreements?
            </h2>
            <h2 className='p-my-5'>
              Looking for a way to keep track of which bridge conventions you
              play and the details of how you play them?
            </h2>
            <h2 className='p-my-5'>
              Looking for a way to refresh your memory about your partnership
              agreements with each of your bridge partners?
            </h2>
            <h2 className='p-my-5'>
              Looking for a bridge convention card that can be as detailed or as
              simple as you want?
            </h2>
            <h5 className='p-my-5'>
              If so, mybridgecontracts.com is what you are looking for. For $15
              you can create and maintain separate personalized Partnership
              Agreements for five of your bridge partners for one year.
            </h5>
            <div className='p-d-flex p-jc-center'>
              <Button
                id='home-page-button'
                className='p-mt-3 p-button-success p-button-raised p-button-rounded'
                onClick={() => history.push("/trial")}
              >
                <Fragment>
                  <h3 className='button-pad-top'>Try the free trial</h3>
                  <h6 className='button-pad-bottom'>and see for yourself</h6>
                </Fragment>
              </Button>
            </div>
          </div>
        </HOME_CONTAINER_DIV>
      </div>
    </Fragment>
  );
};

// const mapStateToProps = (state) => ({
//   user: state.user,
//   contract: state.contract,
// });

export default withRouter(
  connect(null, { unloadContracts, logoutUser })(HomePage)
);
