import React, { Fragment } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const footer = ({ parent, rowData, handleCancel, handleSave }) => {
  return (
    <div>
      <Button
        label='Save'
        icon='pi pi-check'
        onClick={() => {
          handleSave({ rowData: rowData, parent: parent });
        }}
      />
      <Button
        label='Cancel'
        icon='pi pi-times'
        onClick={() => {
          handleCancel({ rowData: rowData, parent: parent });
        }}
      />
    </div>
  );
};

export const nameTemplate = ({
  parent,
  rowData,
  field,
  divideSize,
  minSize,
  header,
  handleEditInput,
  handleSave,
  handleCancel,
}) => {
  let viewName = rowData[field] !== undefined ? rowData[field] : rowData._id;
  if (rowData.editedName !== undefined) viewName = rowData.editedName;
  if (!rowData.isEditing) return viewName;
  let len = viewName.length / divideSize;
  if (len < minSize) len = minSize;

  return (
    <Fragment>
      {viewName}
      <Dialog
        visible={rowData.isEditing}
        header={header}
        closable={false}
        footer={() =>
          footer({
            rowData: rowData,
            handleSave: handleSave,
            handleCancel: handleCancel,
            parent: parent,
          })
        }
        onHide={() => {}}
        style={{ width: `${len}vw` }}
      >
        <div className='p-fluid'>
          <InputText
            value={rowData.editedTempName}
            type='text'
            onFocus={(e) => {
              e.target.select();
            }}
            onChange={(e) => {
              handleEditInput({
                val: e.target.value,
                rowData: rowData,
                parent: parent,
              });
            }}
          ></InputText>
        </div>
      </Dialog>
    </Fragment>
  );
};
