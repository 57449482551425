import React from "react";

const BoldAndItalicsSubcategory = (data) => {
  if (data.subcategoryName === undefined) return <span className='p-d-none' />;

  let b = data.subcategoryBold;
  let i = data.subcategoryItalics;
  if (b && i)
    return (
      <p style={{ paddingLeft: "2em" }} className='bg-manilla'>
        <b>
          <i>{data.subcategoryName}</i>
        </b>
      </p>
    );
  if (b && !i)
    return (
      <p style={{ paddingLeft: "2em" }} className='bg-manilla'>
        <b>{data.subcategoryName}</b>
      </p>
    );
  if (!b && i)
    return (
      <p style={{ paddingLeft: "2em" }} className='bg-manilla'>
        <i>{data.subcategoryName}</i>
      </p>
    );
  return (
    <p style={{ paddingLeft: "2em" }} className='bg-manilla'>
      {data.subcategoryName}
    </p>
  );
};

export default BoldAndItalicsSubcategory;
