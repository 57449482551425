import { RGBColors } from "../../assets/data/colors";

const convertForClient = (str) => {
  let strConverted = str;
  strConverted = strConverted.replace(
    /##c1#/g,
    `<span style="color: ${RGBColors.color1};">`
  );
  strConverted = strConverted.replace(
    /##c2#/g,
    `<span style="color: ${RGBColors.color2};">`
  );
  strConverted = strConverted.replace(
    /##c3#/g,
    `<span style="color: ${RGBColors.color3};">`
  );
  strConverted = strConverted.replace(
    /##c4#/g,
    `<span style="color: ${RGBColors.color4};">`
  );
  strConverted = strConverted.replace(
    /##c5#/g,
    `<span style="color: ${RGBColors.color5};">`
  );

  strConverted = strConverted.replace(/###/g, "</span>");
  strConverted = "<p>" + strConverted + "</p>";
  return strConverted;
};

export const convertFromDb = (contract) => {
  let converted = { ...contract };
  for (let category of converted.categories) {
    for (let subject of category.subjects) {
      if (subject["editedName"] !== undefined)
        subject["editedName"] = convertForClient(subject["editedName"]);
      for (let option of subject.options) {
        if (option["editedName"] !== undefined)
          option["editedName"] = convertForClient(option["editedName"]);
      }
    }
  }
  return converted;
};
