const validateEMailFormat = (email) => {
  let pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern.test(email);
};
const validatePassword = (password, passwordConfirm) =>
  password === passwordConfirm && password.length >= 8;

export const validateInputs = (inputs) => {
  let emailValid = validateEMailFormat(inputs.email);
  let passwordValid = validatePassword(inputs.password, inputs.passwordConfirm);
  let overallValid = emailValid && passwordValid;
  return { emailValid, passwordValid, overallValid };
};
