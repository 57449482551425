import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import axios from "axios";

const ShowSubscriptions = ({ admin }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  useEffect(() => {
    getAllSubscriptions();
  }, [admin.isNewUserAdded]);

  const getAllSubscriptions = async (e) => {
    try {
      const res = await axios.get("/api/v1/bookings", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setSubscriptions(res.data.data);
    } catch (err) {}
  };

  const priceTemplate = (rowData) => `$${rowData.price}.00`;

  const dateTemplate = (rowData) => {
    let [year, month, day] = rowData.createdAt.split("-");
    return `${month}/${day.split("T")[0]}/${year}`;
  };

  const header = (
    <div className='p-d-flex p-jc-between'>
      <p className='m-0 fontl'>Subscribers</p>

      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  return (
    <Fragment>
      <div className='p-shadow-12'>
        <Card className='small-table'>
          <DataTable
            value={subscriptions}
            dataKey='_id'
            className='p-y-025rem'
            paginator={subscriptions.length > 10}
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            header={header}
            globalFilter={globalFilter}
          >
            <Column
              field='user.email'
              header='Email'
              sortable={true}
              style={{ width: "50%" }}
            />

            <Column
              field='createdAt'
              header='Subscribed Date'
              sortable={true}
              body={dateTemplate}
              style={{ width: "40%" }}
            />
            <Column
              field='price'
              header='Price'
              style={{ width: "10%" }}
              body={priceTemplate}
            />
          </DataTable>
        </Card>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});
export default connect(mapStateToProps, null)(ShowSubscriptions);
