import uuid from "react-uuid";

const findItemById = (items, id) => {
  return items.find((item) => item._id === id);
};
const findIndexById = (items, id) => {
  return items.findIndex((item) => item._id === id);
};

const findSubjectandObject = (state, { id, parent }) => {
  let cat = state.selectedCategory;
  let subItem = findItemById(state.contract.categories[cat].subjects, parent);
  let optItem = findItemById(subItem.options, id);
  let sub = findIndexById(state.contract.categories[cat].subjects, parent);
  let opt = findIndexById(subItem.options, id);
  return { cat, sub, opt, subItem, optItem };
};
const isAnyOptionChecked = (subject) => {
  return subject.options.reduce(
    (sum, obj) => (obj.isSelected === true ? true : sum),
    false
  );
};

class OptionHelper {
  static invertedCheckedOption = (state, pay) => {
    const { cat, sub, opt, subItem, optItem } = findSubjectandObject(
      state,
      pay
    );
    const res = { ...state };

    res["contract"].categories[cat].subjects[sub].options[opt].isSelected =
      !optItem.isSelected;
    res["isContractEdited"] = true;
    if (isAnyOptionChecked(subItem))
      res["contract"].categories[cat].subjects[sub].isSelected = true;
    return { ...res };
  };

  static editOption = (state, pay) => {
    const { cat, sub, opt, optItem } = findSubjectandObject(state, pay);
    const res = { ...state };

    res["contract"].categories[cat].subjects[sub].options[opt][
      "isEdited"
    ] = true;

    res["contract"].categories[cat].subjects[sub].options[opt][
      "isEditing"
    ] = true;

    // res["contract"].categories[cat].subjects[sub].options[opt][
    //   "editedTempName"
    // ] =
    //   optItem["isSaved"] === undefined
    //     ? optItem.optionName
    //     : optItem.editedName;
    res["contract"].categories[cat].subjects[sub].options[opt][
        "editedTempName"] = res["contract"].categories[cat].subjects[sub].options[opt][
        "editedName"] !== undefined
          ? optItem.editedName : optItem.optionName


    if (
      res["contract"].categories[cat].subjects[sub].options[opt][
        "editedTempName"
      ].startsWith("button")
    )
      res["contract"].categories[cat].subjects[sub].options[opt][
        "editedTempName"
      ] = "";
    return { ...res };
  };

  static saveOption = (state, pay) => {
    const { cat, sub, opt, subItem, optItem } = findSubjectandObject(
      state,
      pay
    );
    const res = { ...state };

    // res["contract"].categories[cat].subjects[sub].options[opt][
    //   "isSaved"
    // ] = true;
    res["contract"].categories[cat].subjects[sub].options[opt][
      "isSelected"
    ] = true;
    res["contract"].categories[cat].subjects[sub].options[opt]["editedName"] =
      optItem["editedTempName"];
    delete res["contract"].categories[cat].subjects[sub].options[opt][
      "isEditing"
    ];
    delete res["contract"].categories[cat].subjects[sub].options[opt][
      "editedTempName"
    ];
    if (isAnyOptionChecked(subItem))
      res["contract"].categories[cat].subjects[sub].isSelected = true;

    res["isContractEdited"] = true;

    if (
      res["contract"].categories[cat].subjects[sub].options[
        opt
      ].optionName.startsWith("button")
    ) {
      res["contract"].categories[cat].subjects[sub].options[opt].optionName =
        "User added";

      res["contract"].categories[cat].subjects[sub].options.push({
        optionName: "button",
        isSelected: false,
        _id:  uuid(),
      });
    }

    return { ...res };
  };

  static cancelOption = (state, pay) => {
    const { cat, sub, opt } = findSubjectandObject(state, pay);
    const res = { ...state };

    delete res["contract"].categories[cat].subjects[sub].options[opt][
      "isEditing"
    ];
    delete res["contract"].categories[cat].subjects[sub].options[opt][
      "editedTempName"
    ];

    return { ...res };
  };

  static editOptionInput = (state, pay) => {
    const { cat, sub, opt } = findSubjectandObject(state, pay);
    const res = { ...state };

    res["contract"].categories[cat].subjects[sub].options[opt].editedTempName =
      pay.editedTempName;

    return { ...res };
  };
}

export default OptionHelper;
