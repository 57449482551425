import React, { Fragment } from "react";
import { CONTAINER_DIV } from "../../assets/styles/containers.syles";

const InstructionsPage = () => {
  return (
    <Fragment>
      <div className='bg-image'>
        <CONTAINER_DIV className='bg-manilla'>
          <div className='p-4'>
            <h1 className='p-text-center mt-0 pb-1p5'>INSTRUCTIONS</h1>
            <h2 className='p-text-left underline'>FEATURES</h2>
            <p>
              This application allows you to create five separate customized
              Partnership Agreements (System Notes).
            </p>
            <p>
              At any time you can make changes to or start over with any of the
              Partnership Agreements.
            </p>
            <p>
              Each Partnership Agreement can be downloaded at any time to your
              computer as a PDF file.
            </p>

            <h2 className='p-text-left underline'>THE DASHBOARD</h2>

            <p>
              This is the starting point. There you will see a list of 5
              Partnership Agreements with names assigned by the software.
              Initially all 5 Partnership Agreements are blank.
            </p>
            <p>
              Select “Edit Name” to change the name of any Partnership Agreement
              to a name you select, for example, the name of the partner to
              which the Partnership Agreement applies.
            </p>
            <p>
              Select “Load a Template” to undo any Partnership Agreement and
              start over again. You can select any of 6 different starting
              points (templates): a completely blank starting point; starting
              points corresponding to the following Bridge Winners ACBL
              Convention Cards: (1) Basic Precision; (2) Modern Standard
              American; (3) basic 2 over 1; and (4) Bridge Winners Standard; and
              (6) LC Standard.
            </p>
            <p>
              Select “Edit Agreement” when you are ready to customize one of the
              Partnership Agreements. This will take you to the Agreement
              Editor.
            </p>

            <h2 className='p-text-left underline'>THE AGREEMENT EDITOR</h2>

            <p>
              A list of the 13 Categories into which each Partnership Agreement
              is divided appears on the left side of the screen. These
              Categories substantially track the sections on the ACBL Convention
              Card.
            </p>
            <p>
              Select the “View” button to start editing a particular Category.
              Selecting the “View” button will open a list of Subjects, each of
              which has a check box. The Subjects that are in boldface
              correspond to items on the ACBL Convention Card. The Subjects that
              are in italics are listed in Seagram and Smith, “25 Conventions
              You Should Know.”
            </p>
            <p>
              Check the boxes of those Subjects that you want to appear on your
              Partnership Agreement. Checking a Subject will show the Options
              associated with that Subject, each of which has a check box. (You
              can also show or hide the Options associated with any Subject by
              toggling the corresponding “>” in the “View/Hide Options” column.)
            </p>
            <p>
              Check the boxes of those Options that you want to appear on your
              Partnership Agreement. (You can always uncheck a previously
              checked Subject or Option.)
            </p>
            <p>
              Select the “Edit” button to customize any of any Subject or
              Option. For example, in the Overcalls Category one of the Options
              for Additional Agreements for 2NT Overcall (Unusual NT) is “5 or
              more of any major and 4 or more of any minor.” You can change this
              to “5 or more of any major and 5 or more of any minor.” You can
              also use the editing feature to streamline your Partnership
              Agreements. For example, there are separate Subjects for 2D
              Opening, 2H Opening, and 2S Opening. If the requirements for these
              opening bids are the same, change one of the subjects to 2D/2H/2S
              Openings and do not check the other two subjects.
            </p>
            <p>
              You can also use the “Edit” button to change the color of any part
              of the text of any Subject or Option. For example you may want to
              assign different colors to conventions that need to be alerted,
              things you tend to forget, recent changes, etc.
            </p>
            <p>
              Select the “Add New Subject” button or the “Add New Option” button
              to create additional Subjects and Options which you can fill with
              text of your own choosing.
            </p>
            <p>
              Select the “Save Changes to Agreement” button located in the box
              in the top left corner to save the changes you have made.
            </p>
            <p>
              After you have saved any changes you can select “Download PDF” to
              download your Partnership Agreement. When you open the downloaded
              PDF file on your computer, toggle the icons at the top left corner
              to access your Partnership Agreement in different ways.
            </p>

            <h2 className='p-text-left underline'>COMMENTS</h2>
            <p>
              Use the “Contact Us” buttons to send any comments, criticisms or
              suggestions.
            </p>
            <p>Good luck and hope you like it!</p>
          </div>
        </CONTAINER_DIV>
      </div>
    </Fragment>
  );
};

export default InstructionsPage;
