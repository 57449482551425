import React, { useEffect, useState, useRef, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { confirmDialog } from "primereact/confirmdialog";
import { nameTemplate } from "../templates/nameTemplate";
//import PaymentForm from "../stripe/payment-form.component";

import {
  getMyContractNames,
  createContract,
  editContractName,
  editContractNameInput,
  saveContractName,
  cancelContractName,
  loadContract,
  // resetContract,
  getTemplateNames,
  resetToTemplate,
  updateToLatestRevision,
  // testLoadContract,
  // testSelectAllItemsInContract,
} from "../../redux/contract/contract.actions";

const Dashboard = ({
  user,
  history,
  contract,
  getMyContractNames,
  createContract,
  editContractName,
  editContractNameInput,
  saveContractName,
  cancelContractName,
  loadContract,
  // resetContract,
  getTemplateNames,
  resetToTemplate,
  updateToLatestRevision,
}) => {
  const [showReset, setShowReset] = useState(false);
  const [tempContractId, setTempContractId] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const toast = useRef(null);
  useEffect(() => {
    getMyContracts();
    // eslint-disable-next-line
  }, []);

  const getMyContracts = async () => {
    let auth = true;
    if (user.isLoggedIn === false) auth = false;
    await getMyContractNames(auth);
    if (user.isLoggedIn === true) await getTemplateNames();
  };

  // const handleNewContract = async () => {
  //   await createContract();
  // };

  const handleSaveContractName = ({ rowData }) => {
    rowData["isSubscriber"] = user.isLoggedIn;
    saveContractName(rowData);
  };
  const handleCancelContractName = ({ rowData }) => {
    cancelContractName(rowData._id);
  };
  const handleEditContractNameInput = ({ val, rowData }) => {
    const pay = { id: rowData._id, editedTempName: val };
    editContractNameInput(pay);
  };

  const nameT = (rowData) => {
    return nameTemplate({
      rowData: rowData,
      field: "contractName",
      divideSize: 1.5,
      minSize: 25,
      header: "Edit the Agreement Name",
      handleEditInput: handleEditContractNameInput,
      handleSave: handleSaveContractName,
      handleCancel: handleCancelContractName,
    });
  };

  const editNameTemplate = (rowData) => {
    return (
      rowData.isEditing === undefined && (
        <Button
          label='Edit Name'
          className='p-button-raised p-mr-2'
          onClick={() => {
            editContractName(rowData._id);
          }}
          // disabled={!user.isLoggedIn}
        />
      )
    );
  };

  ////////////////////////////////////////////////////////////////
  const buttonTemplate = (rowData) => {
    const handleEdit = async () => {
      if (user.isLoggedIn === true) {
        // await loadContract(rowData._id, true);
        await loadContract(rowData, true);
        history.push("/my/contract");
      } else {
        // await loadContract(rowData._id, false);
        await loadContract(rowData, false);
        history.push("/guest/contract");
      }
    };

    const handleReset = async () => {
      setTempContractId(rowData._id);
      setShowReset(true);
    };
    const confirm = () => {
      confirmDialog({
        message: "This will completely erase the existing Agreement.",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "OK",
        rejectLabel: "Cancel",
        accept: () => {
          resetToTemplate({
            templateId: templateName,
            contractId: tempContractId,
          });
          setShowReset(false);
          setTempContractId(null);
        },
        reject: () => {},
      });
    };
    const handleSave = async () => {
      confirm();
    };
    const handleCancel = async () => {
      setShowReset(false);
    };
    const renderFooter = () => {
      return (
        <Fragment>
          <Button
            label='Cancel'
            className='p-button-text'
            // icon='pi pi-times'
            onClick={() => {
              handleCancel();
            }}
          />
          <Button
            label='Reset'
            // icon='pi pi-check'
            onClick={() => {
              handleSave();
            }}
            disabled={templateName === null}
          />
        </Fragment>
      );
    };
    return (
      <Fragment>
        <Dialog
          header='Reset to Template'
          modal={false}
          visible={showReset}
          style={{ width: "50vw" }}
          onHide={() => setShowReset(false)}
          footer={renderFooter}
        >
          {contract.templateNames !== undefined &&
            contract.templateNames.map((template) => (
              <Fragment key={template._id}>
                <div className='p-field-radiobutton'>
                  <RadioButton
                    value={template._id}
                    key={template._id}
                    name='templateName'
                    checked={templateName === template._id}
                    onChange={(e) => {
                      setTemplateName(e.value);
                    }}
                  />
                  <label htmlFor={template._id}>{template.templateName}</label>
                </div>
              </Fragment>
            ))}
        </Dialog>
        <Button
          iconPos='left'
          icon='pi pi-pencil'
          label='Edit Agreement'
          className='p-button-raised p-button-warning p-mr-2'
          onClick={handleEdit}
        />
        <Button
          iconPos='left'
          // icon='pi pi-times-circle'
          label='Load a Template'
          className='p-button-raised p-button-danger'
          onClick={handleReset}
          disabled={!user.isLoggedIn}
        />
        {/* {user.role==='admin' && <Button label='Load and Select All' onClick={handleLoadSelectAll} />} */}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className='p-grid bg-image' id='dashboard'>
        <div className='p-col-8 p-offset-2'>
          <div className='p-shadow-24 p-m-2'>
            <Card title='Dashboard' className='bg-manilla'>
              <div className='p-d-flex p-flex-column p-jc-around'>
                <DataTable value={contract.contractNames} dataKey='_id'>
                  <Column
                    header='Agreement Name'
                    body={nameT}
                    style={{ width: "25%" }}
                  ></Column>
                  <Column
                    header='Edit Name'
                    body={editNameTemplate}
                    style={{ width: "15%" }}
                  ></Column>
                  <Column body={buttonTemplate} style={{ width: "35%" }} />
                </DataTable>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  contract: state.contract,
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    getMyContractNames,
    createContract,
    editContractName,
    editContractNameInput,
    saveContractName,
    cancelContractName,
    loadContract,
    // resetContract,
    getTemplateNames,
    resetToTemplate,
    updateToLatestRevision,
    // testLoadContract,
    // testSelectAllItemsInContract,
  })(Dashboard)
);
