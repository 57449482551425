const forgotPasswordInputs = {
  email: {
    label: "Email Address",
    name: "email",
    id: "email-address3",
    medSize: 12,
    required: true,
    minimum: 2,
    maximum: 99999,
    textError: "Please enter an Email Address",
    validate: "text",
  },
};

export default forgotPasswordInputs;
