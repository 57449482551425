import React, { Fragment } from "react";

import Contract from "../../components/contract/contract.component";

const MyContractPage = () => {
  return (
    <Fragment>
      <div className='bg-image'>
        <div className='p-shadow-12'>
          <Contract />
        </div>
      </div>
    </Fragment>
  );
};

export default MyContractPage;
