import styled from 'styled-components';

export const CONTAINER = styled.div`
  width: ${(props) => props.width && props.width};
  margin: 0 auto;
`;


export const CONTAINER_DIV = styled.div`
  margin: 0 auto;
  width: 100%;
  @media (min-width: 576px) {
    width: 576px;
    padding: 0;
  }

  @media (min-width: 768px) {
    width: 768px;
    padding: 0;
  }

  @media (min-width: 992px) {
    width: 992px;
    padding: 0;
  }

  @media (min-width: 1200px) {
    width: 1200px;
    padding: 0;
  }
`;
export const HOME_CONTAINER_DIV = styled.div`
  margin: 0 auto;
  width: 100%;
  @media (min-width: 576px) {
    width: 576px;
    padding: 0;
  }

  @media (min-width: 768px) {
    width: 668px;
    padding: 0;
  }

  @media (min-width: 992px) {
    width: 750px;
    padding: 0;
  }

  @media (min-width: 1200px) {
    width: 950px;
    padding: 0;
  }
  @media (min-width: 1400px) {
    width: 1150px;
    padding: 0;
  }
`;
