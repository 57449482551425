import React, { Fragment, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

const ContractStatus = ({ contract, handlers }) => {
  const { handleSaveContract, handleMakePdf } = handlers;
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    await handleSaveContract();
    setSaving(false);
  };

  return (
    <Card className='p-m-2 bg-manilla'>
      <div className='p-grid'>
        <div className='p-col-12'>
          <div className='p-card-title p-text-center'>
            {" "}
            {contract.contract === null ? "Agreement Status" : "Agreement Name"}
          </div>
          {contract.contract === null ? (
            <h5 className=' p-text-center'>Agreement is not Loaded</h5>
          ) : (
            <h5 className=' p-text-center'>{contract.contract.contractName}</h5>
          )}
        </div>
        <div className='p-col-12'>
          <div className='p-card-title p-text-center '>
            {contract.isContractEdited && contract.isSubscriber ? (
              <Button
                label='Save Changes to Agreement'
                onClick={handleSave}
                loadingIcon='pi pi-spin pi-sun'
                loading={saving}
              />
            ) : (
              <Fragment>
                <Button
                  label='Download PDF'
                  onClick={handleMakePdf}
                  className='p-mr-2'
                />
                {/* <Button
                  label='Create PDF 2'
                  onClick={handleMakePdf2}
                  className='p-ml-2'
                /> */}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ContractStatus;
