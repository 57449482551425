import React from "react";

const BoldAndItalics = (rowData) => {
  let typeName = "";
  if (rowData["categoryName"] !== undefined) typeName = rowData.categoryName;
  if (rowData["subjectName"] !== undefined) typeName = rowData.subjectName;
  if (rowData["field"] === "option") typeName = rowData.optionName;
  let b = rowData.bold;
  let i = rowData.italics;
  if (b && i)
    return (
      <b>
        <i>{typeName}</i>
      </b>
    );
  if (b && !i) return <b>{typeName}</b>;
  if (!b && i) return <i>{typeName}</i>;
  return typeName;
};

export default BoldAndItalics;
