import React, { Fragment } from "react";

import PdfViewer2 from "../../components/pdf-viewer/pdf-viewer2.component";

const MyPdfViewPage2 = () => {
  return (
    <Fragment>
      <div className='bg-image'>
        <div className='p-shadow-12'>
          <PdfViewer2 />
        </div>
      </div>
    </Fragment>
  );
};

export default MyPdfViewPage2;
