import React, { Fragment } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
//import "primeflex/src/_variables.scss";
//import 'primereact/resources/themes/saga-blue/theme.css';
//import 'primereact/resources/themes/vela-blue/theme.css';
// import 'primereact/resources/themes/luna-blue/theme.css';
// import "primereact/resources/themes/arya-blue/theme.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
//import "primereact/resources/themes/bootstrap4-dark-blue/theme.css";
//import 'primereact/resources/themes/mdc-light-indigo/theme.css';
//import "primereact/resources/themes/mdc-dark-indigo/theme.css";

import PrivateRoute from "./routing/private-route";
import AdminRoute from "./routing/admin-route";
import HomePage from "./pages/home-page/home-page.component";
import TrialPage from "./pages/trial-page/trial-page.component";
import LoginPage from "./pages/login-page/login-page.component";
import RegisterPage from "./pages/register-page/register-page.component";
import RegisterFail from "./pages/register-fail/register-fail.component";
import RegisterPass from "./pages/register-pass/register-pass.component";
import ForgotPasswordPage from "./pages/forgot-password-page/forgot-password-page.component";
import ResetPasswordPage from "./pages/reset-password-page/reset-password-page.component";
import AcceptInvitePage from "./pages/accept-invite-page/accept-invite-page.component";
import InstructionsPage from "./pages/instructions-page/instructions-page.component";
import ContactPage from "./pages/contact-page/contact-page.component";
import GuestHomePage from "./pages/guestHome-page/guestHome-page.component";
import ImHomePage from "./pages/imHome-page/imHome-page.component";
import MyHomePage from "./pages/myHome-page/myHome-page.component";
import MyContractPage from "./pages/myContract-page/myContract-page.component";
import MyPdfViewPage from "./pages/myPdfView-page/myPdfView-page.component";
import MyPdfViewPage2 from "./pages/myPdfView-page/myPdfView-page2.component";
import MyAdminPage from "./pages/myAdmin-page/myAdmin-page.component";
import Navbar from "./components/navbar/navbar.component";
import "./App.css";

function App() {
  return (
    <Fragment>
      <Router>
        <Navbar />
        <Switch>
          <PrivateRoute exact path='/my/home' component={MyHomePage} />
          <PrivateRoute exact path='/im/home' component={ImHomePage} />
          <PrivateRoute
            exact
            path='/my/instructions'
            component={InstructionsPage}
          />
          <PrivateRoute exact path='/my/contact' component={ContactPage} />
          <PrivateRoute exact path='/my/contract' component={MyContractPage} />
          <PrivateRoute exact path='/my/pdfview' component={MyPdfViewPage} />
          <PrivateRoute exact path='/my/pdfview2' component={MyPdfViewPage2} />
          <AdminRoute exact path='/my/admin' component={MyAdminPage} />
          <Route exact path='/' component={HomePage} />
          <Route exact path='/instructions' component={InstructionsPage} />
          <Route exact path='/contact' component={ContactPage} />
          <Route exact path='/trial' component={TrialPage} />
          <Route exact path='/pdfview' component={MyPdfViewPage} />
          <Route exact path='/login' component={LoginPage} />
          {/* <Route exact path='/login/changed' component={LoginPage} /> */}
          <Route exact path='/login/:token' component={LoginPage} />
          <Route exact path='/forgot-password' component={ForgotPasswordPage} />
          <Route
            exact
            path='/reset-password/:tok'
            component={ResetPasswordPage}
          />
          <Route
            exact
            path='/accept-invite/:tok'
            component={AcceptInvitePage}
          />
          <Route exact path='/register' component={RegisterPage} />
          <Route exact path='/register-pass' component={RegisterPass} />
          <Route exact path='/register-fail' component={RegisterFail} />
          <Route exact path='/guest/home' component={GuestHomePage} />
          <Route exact path='/guest/contract' component={MyContractPage} />
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
