const findNameAndIndexById = (items, id) => {
  let cn = items.findIndex((cn) => cn._id === id);
  let cname = items.find((c) => c._id === id);
  return { cn, cname };
};

class ContractHelper {
  static selectedCategory = (state, id) => {
    const res = { ...state };
    let cat = state.contract.categories.findIndex((ca) => ca._id === id);
    res["selectedCategory"] = cat;
    return { ...res };
  };

  static editContractName = (state, id) => {
    let { cn, cname } = findNameAndIndexById(state.contractNames, id);
    const res = { ...state };
    res.contractNames[cn]["editedTempName"] =
      cname.contractName === undefined ? cname._id : cname.contractName;

    res.contractNames[cn]["isEditing"] = true;
    return { ...res };
  };
  static editContractNameInput = (state, pay) => {
    const { id, editedTempName } = pay;
    const res = { ...state };
    let cname = state.contractNames.findIndex((cn) => cn._id === id);
    res.contractNames[cname]["editedTempName"] = editedTempName;
    return { ...res };
  };
  static cancelContractName = (state, id) => {
    let { cn } = findNameAndIndexById(state.contractNames, id);
    const res = { ...state };

    delete res.contractNames[cn].isEditing;
    delete res.contractNames[cn].editedTempName;

    return { ...res };
  };
}

export default ContractHelper;
