export const AdminActionTypes = {
  UPLOAD_MASTER_PASS: "UPLOAD_MASTER_PASS",
  UPLOAD_MASTER_FAIL: "UPLOAD_MASTER_FAIL",
  ADD_NEW_USER_PASS: "ADD_NEW_USER_PASS",
  ADD_NEW_USER_FAIL: "ADD_NEW_USER_FAIL",
  IMPERSONATE_USER_PASS: "IMPERSONATE_USER_PASS",
  IMPERSONATE_USER_FAIL: "IMPERSONATE_USER_FAIL",
  UPLOAD_PRODUCT_PASS: "UPLOAD_PRODUCT_PASS",
  UPLOAD_PRODUCT_FAIL: "UPLOAD_PRODUCT_FAIL",
  UPLOAD_GUEST_PASS: "UPLOAD_GUEST_PASS",
  UPLOAD_GUEST_FAIL: "UPLOAD_GUEST_FAIL",
  MASTER_CONTRACT_READY: "MASTER_CONTRACT_READY",
  ADD_NEW_USER_READY: "ADD_NEW_USER_READY",
};
