import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import BoldAndItalics from "../templates/boldAndItalics.component";
import { convertToRomanNumeral } from "../../utilities/numbers";

const ContractCategory = ({ category, handlers }) => {
  const { handleViewCategory } = handlers;
  const romanNumeral = (field) => {
    return convertToRomanNumeral(field.order);
  };

  const viewTemplate = (rowData) => {
    return (
      <Button
        label='View'
        icon='pi pi-check'
        className='p-button-raised p-button-success p-mr-2'
        onClick={() => {
          handleViewCategory(rowData);
        }}
      />
    );
  };

  return (
    <Card
      title='Categories'
      className='bg-manilla category-list p-m-2 p-text-center'
    >
      <DataTable value={category} dataKey='_id'>
        <Column
          field='order'
          header='Number'
          style={{ width: "13%" }}
          body={romanNumeral}
          className='bg-manilla'
        />
        <Column
          field='categoryName'
          body={BoldAndItalics}
          header='Category'
          style={{ width: "52%" }}
          className='bg-manilla'
        />
        <Column
          body={viewTemplate}
          style={{ width: "35%" }}
          className='bg-manilla'
        />
      </DataTable>
    </Card>
  );
};

export default ContractCategory;
