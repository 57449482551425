import { combineReducers } from "redux";

import userReducer from "./user/user.reducer";
import adminReducer from "./admin/admin.reducer";
import contractReducer from "./contract/contract.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  contract: contractReducer,
  admin: adminReducer,
});

export default rootReducer;
