import React, { useState, useRef } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Captcha } from "primereact/captcha";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import validator from "validator";
import axios from "axios";

import { CONTAINER } from "../../assets/styles/containers.syles";

const Contact = () => {
  const toast = useRef(null);
  const [messageSent, setMessageSent] = useState(false);

  const [inputs, setInputs] = useState({
    mailName: "",
    mailEmail: "",
    mailMessage: "",
  });
  const [isVerified, setIsVerified] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const { mailName, mailEmail, mailMessage } = inputs;

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSendMessage = async () => {
    if (!validator.isEmail(mailEmail)) {
      setEmailError("Enter valid Email!");
      return;
    }
    setEmailError("");
    if (mailMessage.length < 10) {
      setMessageError("Message must be at least 10 characters");
      return;
    }
    setMessageError("");
    if (isVerified === false) return;
    let cont = { mailName, mailEmail, mailMessage };
    await axios.post("/api/v1/messages", cont, {
      headers: { "Content-Type": "application/json" },
    });
    setMessageSent(true);
  };
  const showResponse = () => {
    setIsVerified(true);
  };

  const header = <h1 className='p-text-center'>Contact Us</h1>;
  return (
    <CONTAINER width='40%'>
      <Toast ref={toast}></Toast>
      <div className='p-shadow-12 p-d-block p-mx-auto'>
        <Card header={header} className='bg-manilla p-pt-3'>
          <div className='p-fluid p-grid p-dir-col'>
            <div className='p-field pcol-12'>
              <label htmlFor='mail-name'>Full Name (Optional)</label>
              <InputText
                id='mail-name'
                name='mailName'
                value={mailName}
                disabled={false}
                onChange={handleChange}
              />
            </div>
            <div className='p-field pcol-12'>
              <label htmlFor='mail-email'>Email Address</label>
              <InputText
                id='mail-email'
                name='mailEmail'
                value={mailEmail}
                disabled={false}
                onChange={handleChange}
                className={emailError !== "" && "p-invalid"}
              />
              <p className='color-error'>{emailError}</p>
            </div>
            <div className='p-field pcol-12'>
              <label htmlFor='mail-message'>Message</label>
              <InputTextarea
                id='mail-message'
                name='mailMessage'
                value={mailMessage}
                disabled={false}
                rows={10}
                onChange={handleChange}
                className={messageError !== "" && "p-invalid"}
              />
            </div>
            <p className='color-error'>{messageError}</p>
            <div className='p-d-flex p-jc-evenly p-mb-3'>
              <Captcha
                siteKey='6LdVYgEcAAAAANvuT6P2ALev6OTh7jxXPa9FMRqQ'
                onResponse={showResponse}
              />
            </div>
            <div className='p-d-flex p-jc-evenly'>
              <Button
                label='Send Message'
                disabled={messageSent || !isVerified}
                onClick={handleSendMessage}
              />
            </div>
            {messageSent === true && (
              <h4 className='p-text-center'>Your message was sent</h4>
            )}
          </div>
        </Card>
      </div>
    </CONTAINER>
  );
};

export default Contact;
