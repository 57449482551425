import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { validateInputs } from "./login.helpers";
import LoginMessages from "./login-messages.component";
import { clearContract } from "../../redux/contract/contract.actions";
import { CONTAINER } from "../../assets/styles/containers.syles";
import { loginUser, clearErrors } from "../../redux/user/user.actions";

const Login = ({
  history,
  user: { isLoggedIn, subscriberStatus, isAuthFail, isConfirmedEmailFail },
  loginUser,
  clearErrors,
  msg,
}) => {
  const [loading, setLoading] = useState(false);
  const [userIn, setUserIn] = useState({
    email: "",
    password: "",
    emailValid: true,
    passwordValid: true,
  });
  const { email, password } = userIn;

  useEffect(() => {
    if (isLoggedIn) {
      clearContract();
      if (subscriberStatus !== "expired" && subscriberStatus !== "cancelled")
        return history.push("/my/home");
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    clearErrors();
    // eslint-disable-next-line
  }, []);

  const handleForgotPassword = async (e) => {
    history.push("/forgot-password");
  };

  const handleLogin = async (e) => {
    let { emailValid, passwordValid, overallValid } = validateInputs(userIn);
    setUserIn({ ...userIn, emailValid, passwordValid });
    if (!overallValid) return;

    let userItem = { email, password };
    try {
      setLoading(true);
      await loginUser(userItem);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const handleInputChange = (e) => {
    setUserIn({ ...userIn, [e.target.name]: e.target.value });
  };
  return (
    <CONTAINER width='30%'>
      <div className='p-shadow-12 p-d-block p-mx-auto'>
        <Card title='Login' className='bg-manilla'>
          <div className='p-fluid p-grid p-dir-col'>
            {msg !== undefined && <h4 className='p-text-center'>{msg}</h4>}

            <div className='p-field p-col-12'>
              <label htmlFor='email-address2'>Email Address</label>
              <InputText
                id='email-address2'
                name='email'
                value={email}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>

            <div className='p-field p-col-12'>
              <label htmlFor='password-2'>Password</label>
              <Password
                id='password-2'
                name='password'
                value={password}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>

            <Button
              label='Forgot password?'
              onClick={handleForgotPassword}
              className='mb-14 p-button-text p-button-help p-text-right'
            />

            <div className='p-d-flex p-jc-evenly'>
              <Button
                label='Sign In'
                onClick={handleLogin}
                loadingIcon='pi pi-spin pi-sun'
                loading={loading}
              />
            </div>
            <LoginMessages
              isSubscribedFail={
                subscriberStatus === "cancelled" ||
                subscriberStatus === "expired"
              }
              emailValid={userIn.emailValid}
              passwordValid={userIn.passwordValid}
              isConfirmedEmailFail={isConfirmedEmailFail}
              isAuthFail={isAuthFail}
            />
            <Button
              label='Sign Up for a New Account'
              className='p-button-text p-button-plain p-mt-3'
              onClick={() => {
                history.push("/register");
              }}
            />
          </div>
        </Card>
      </div>
    </CONTAINER>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { loginUser, clearErrors })(Login)
);
