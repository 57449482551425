import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import InputFieldText from "../input-fields/input-field-text";
import InputFieldPassword from "../input-fields/input-field-password";

import registerInputs from "../../assets/data/register.data";

import {
  addNewUser,
  makeAddNewUserReady,
} from "../../redux/admin/admin.actions";

const AddUser = ({ admin, addNewUser, makeAddNewUserReady }) => {
  const [adding, setAdding] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [userIn, setUserIn] = useState({
    email: "",
  });
  const { email } = userIn;
  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, []);

  const initPage = async () => {
    await makeAddNewUserReady();
  };
  const handleShowDialog = (e) => {
    setShowDialog(true);
  };
  const hideDialog = () => {
    setShowDialog(false);
  };
  const handleRegister = async (e) => {
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(email) === false) {
      setEmailError(true);
      return;
    }
    let userItem = { email };

    try {
      setAdding(true);
      await addNewUser(userItem);
      setAdding(false);
      setShowDialog(false);
      setUserIn({
        email: "",
      });
    } catch (err) {}
  };
  const handleInputChange = (e) => {
    setUserIn({ ...userIn, [e.target.name]: e.target.value });
    setEmailError(false);
  };

  return (
    <Fragment>
      <Dialog
        header='Add User'
        footer=''
        visible={showDialog}
        style={{ width: "30vw" }}
        onHide={hideDialog}
        baseZIndex={1000}
      >
        <Fragment>
          <div className='p-fluid p-grid p-dir-col'>
            <InputFieldText
              variables={{ value: email, disabled: false, showError: false }}
              constants={registerInputs.email}
              handler={handleInputChange}
            />
          </div>
          {emailError === true && <h6>Incorrect email format</h6>}
          <div className='p-d-flex p-jc-evenly'>
            <Button
              onClick={handleRegister}
              label='Register'
              className='p-button-success'
              loadingIcon='pi pi-spin pi-sun'
              loading={adding}
            />

            <Button
              onClick={hideDialog}
              label='Cancel'
              className='p-button-warning'
            />
          </div>
        </Fragment>
      </Dialog>
      <Button onClick={handleShowDialog} label='Add User' />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, {
  makeAddNewUserReady,
  addNewUser,
})(AddUser);
