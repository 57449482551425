import React, { Fragment, useEffect } from "react";
import { withRouter, Prompt } from "react-router-dom";
import { connect } from "react-redux";

import ContractStatus from "./contract-status.component";
import ContractCategory from "./contract-category.component";
import ContractSubject from "./contract-subject.component";

import {
  setSelectedCategory,
  invertCheckedSubject,
  invertCheckedOption,
  setEditSubject,
  setSaveSubject,
  setCancelSubject,
  setEditSubjectInput,
  setEditOption,
  setSaveOption,
  setCancelOption,
  setEditOptionInput,
  saveContract,
  makePdfFile,
} from "../../redux/contract/contract.actions";
import axios from "axios";

const Contract = ({
  history,
  contract,
  setSelectedCategory,
  invertCheckedSubject,
  invertCheckedOption,
  setEditSubject,
  setSaveSubject,
  setCancelSubject,
  setEditSubjectInput,
  setEditOption,
  setSaveOption,
  setCancelOption,
  setEditOptionInput,
  saveContract,
  makePdfFile,
}) => {
  useEffect(() => {
    if (contract.pdfiFileInfo !== null) downloadPdf();
    // eslint-disable-next-line
  }, [contract.pdfFileInfo]);

  const handleViewCategory = (row) => {
    setSelectedCategory(row._id);
  };

  const handleCheckSubject = (row) => {
    invertCheckedSubject(row._id);
  };
  const handleEditSubject = (row) => {
    setEditSubject(row._id);
  };
  const handleSaveSubject = ({ rowData }) => {
    setSaveSubject(rowData._id);
  };
  const handleCancelSubject = ({ rowData }) => {
    setCancelSubject(rowData._id);
  };
  const handleEditSubjectInput = ({ val, rowData }) => {
    if (val === null) return;

    let newVal = val.replace("</p><p>", "");

    const pay = { id: rowData._id, editedTempName: newVal };
    setEditSubjectInput(pay);
  };

  const handleCheckOption = ({ rowData, parent }) => {
    const pay = { id: rowData._id, parent: parent };
    invertCheckedOption(pay);
  };
  const handleEditOption = ({ rowData, parent }) => {
    const pay = { id: rowData._id, parent: parent };

    setEditOption(pay);
  };
  const handleSaveOption = ({ rowData, parent }) => {
    const pay = { id: rowData._id, parent: parent };
    setSaveOption(pay);
  };
  const handleCancelOption = ({ rowData, parent }) => {
    const pay = { id: rowData._id, parent: parent };
    setCancelOption(pay);
  };
  const handleEditOptionInput = ({ val, rowData, parent }) => {
    if (val === null) return;
    let newVal = val.replace("</p><p>", "");
    const pay = { id: rowData._id, editedTempName: newVal, parent: parent };
    setEditOptionInput(pay);
  };

  const handleSaveContract = async () => {
    const con = contract.contract;
    await saveContract(con);
    return true;
  };
  const handleMakePdf = async () => {
    const con = {
      contract: contract.contract,
      subscriber: contract.isSubscriber,
    };
    await makePdfFile(con);
  };
  const downloadPdf = async () => {
    if (contract.pdfFileInfo === null || contract.pdfiFileInfo === "undefined")
      return;
    if (
      contract.pdfFileInfo.fileName === null ||
      contract.pdfFileInfo.fileName === "undefined"
    )
      return;

    let res = await axios(
      `/api/v1/contracts/pdfviewer/${contract.pdfFileInfo.pathName}/${contract.pdfFileInfo.fileName}`,
      {
        method: "GET",
        responseType: "blob",
      }
    );

    let blob = await new Blob([res.data], {
      type: "application/pdf",
    });
    let link = document.createElement("a");
    link.download = `${contract.pdfFileInfo.fileName}`;

    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  const handleMakePdf2 = async () => {
    const con = {
      contract: contract.contract,
      subscriber: contract.isSubscriber,
    };
    await makePdfFile(con);
    contract.isSubscriber
      ? history.push("/my/pdfview2")
      : history.push("/pdfview");
  };

  return (
    <Fragment>
      <div className='p-grid'>
        <div className='p-col-3'>
          {/* {information && (
            <div className='p-shadow-24 p-m-2'>
              <ContractInformation />
            </div>
          )} */}
          <div className='p-shadow-24 p-m-2'>
            <ContractStatus
              contract={contract}
              handlers={{ handleSaveContract, handleMakePdf, handleMakePdf2 }}
            />
          </div>
          {contract.contract !== null && (
            <div className='p-shadow-24 p-m-2'>
              <ContractCategory
                category={contract.contract.categories}
                handlers={{ handleViewCategory }}
              />
            </div>
          )}
        </div>

        <div className='p-col-9'>
          {contract.selectedCategory !== -1 && (
            <div className='p-shadow-24 p-m-2'>
              <ContractSubject
                category={
                  contract.contract.categories[contract.selectedCategory]
                }
                handlers={{
                  handleEditSubject,
                  handleSaveSubject,
                  handleCancelSubject,
                  handleEditSubjectInput,
                  handleCheckSubject,
                }}
                optionHandlers={{
                  handleEditOption,
                  handleEditOptionInput,
                  handleSaveOption,
                  handleCancelOption,
                  handleCheckOption,
                }}
              />
            </div>
          )}
        </div>
      </div>

      <Prompt
        when={contract.isSubscriber && contract.isContractEdited}
        message='Press OK to proceed without saving changes'
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  contract: state.contract,
});
export default withRouter(
  connect(mapStateToProps, {
    setSelectedCategory,
    invertCheckedSubject,
    invertCheckedOption,
    setEditSubject,
    setSaveSubject,
    setCancelSubject,
    setEditSubjectInput,
    setEditOption,
    setSaveOption,
    setCancelOption,
    setEditOptionInput,
    saveContract,
    makePdfFile,
  })(Contract)
);
