import axios from "axios";
import { AdminActionTypes } from "./admin.types";

//const fileConfig = { headers: { "Content-Type": 'multipart/form-data' }}
const fileConfig = {
  headers: { "Content-Type": "multipart/form-data" },
  withCredentials: true,
};
const config = { headers: { "Content-Type": "application/json" } };
const configWithCred = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

export const uploadMasterContract = (formInfo) => async (dispatch) => {
  try {
    await axios.post(`/api/v1/masters`, formInfo, fileConfig);
    return dispatch({
      type: AdminActionTypes.UPLOAD_MASTER_PASS,
    });
  } catch (err) {
    return dispatch({
      type: AdminActionTypes.UPLOAD_MASTER_FAIL,
    });
  }
};

export const impersonateUser = (id) => async (dispatch) => {
  try {
    return dispatch({
      type: AdminActionTypes.IMPERSONATE_USER_PASS,
      payload: id,
    });
  } catch (err) {
    return dispatch({
      type: AdminActionTypes.IMPERSONATE_USER_FAIL,
    });
  }
};

export const addNewUser = (userItem) => async (dispatch) => {
  try {
    await axios.post("/api/v1/users/admin-signup", userItem, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return dispatch({
      type: AdminActionTypes.ADD_NEW_USER_PASS,
    });
  } catch (err) {
    return dispatch({
      type: AdminActionTypes.ADD_NEW_USER_FAIL,
    });
  }
};

export const makeMasterContractReady = () => async (dispatch) => {
  return dispatch({
    type: AdminActionTypes.MASTER_CONTRACT_READY,
  });
};
export const makeAddNewUserReady = () => async (dispatch) => {
  return dispatch({
    type: AdminActionTypes.ADD_NEW_USER_READY,
  });
};
export const uploadMasterProduct = (body) => async (dispatch) => {
  try {
    await axios.post(`/api/v1/products`, body, config);
    return dispatch({
      type: AdminActionTypes.UPLOAD_PRODUCT_PASS,
    });
  } catch (err) {
    return dispatch({
      type: AdminActionTypes.UPLOAD_PRODUCT_FAIL,
    });
  }
};
export const uploadGuestContract = (categories) => async (dispatch) => {
  try {
    await axios.post(`/api/v1/contracts/guest`, categories, configWithCred);
    return dispatch({
      type: AdminActionTypes.UPLOAD_GUEST_PASS,
    });
  } catch (err) {
    return dispatch({
      type: AdminActionTypes.UPLOAD_GUEST_FAIL,
    });
  }
};
