import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Trial from "../../components/trial/trial.component";
import { loadGuestContract } from "../../redux/contract/contract.actions";

const TrialPage = ({ contract, loadGuestContract }) => {
  useEffect(() => {
    getGuestContract();
    // eslint-disable-next-line
  }, []);

  const getGuestContract = async () => {
    if (contract.isContractEdited) return;
    await loadGuestContract();
  };

  return (
    <Fragment>
      <div className='bg-image'>
        <div className='p-shadow-12'>
          <Trial />
          {/* {contract.contract !== null && <Contract information={true} />} */}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  contract: state.contract,
});

export default connect(mapStateToProps, { loadGuestContract })(TrialPage);
