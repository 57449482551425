import React, { Fragment } from "react";
import { Card } from "primereact/card";
import { CONTAINER } from "../../assets/styles/containers.syles";

const RegisterFail = () => {
  return (
    <Fragment>
      <div className='bg-image'>
        <div className='p-shadow-12'>
          <CONTAINER width='30%'>
            <div className='p-shadow-12 p-d-block p-mx-auto'>
              <Card
                title='Your Registration Did Not Complete'
                className='bg-manilla'
              >
                <h4 className='p-text-center'>
                  Please try again or contact us at
                </h4>
                <h5 className='p-text-center'>
                  administrator@mybridgecontracts.com
                </h5>
              </Card>
            </div>
          </CONTAINER>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterFail;
