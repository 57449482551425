import React, {Fragment,useState } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

import EditorTemplate from "../templates/EditorTemplate";
import ContractOption from "./contract-option.component";
import BoldAndItalicsSubcategory from "../templates/boldAndItalicsSubcategory.component";

const ContractSubject = ({ handlers, category, optionHandlers }) => {
  const [expandedRows, setExpandedRows] = useState(null);
  const {
    handleCheckSubject,
    handleEditSubject,
    handleEditSubjectInput,
    handleSaveSubject,
    handleCancelSubject,
  } = handlers;

  const nameT = (rowData) => {
    if (
      rowData.subjectName.startsWith("button") &&
      rowData.isEditing === undefined &&
      rowData.editedName === undefined
    )
      return (
        <Button
          label='Add New Subject'
          className='p-button-raised'
          onClick={() => {
            handleEditSubject(rowData);
          }}
        />
      );

    return (
      <EditorTemplate
        rowData={rowData}
        field='subjectName'
        header='Edit the Subject Name'
        handleEditInput={handleEditSubjectInput}
        handleSave={handleSaveSubject}
        handleCancel={handleCancelSubject}
      />
    );
  };

  const editTemplate = (rowData) => {
    if (
      rowData.isEditing === undefined &&
      rowData.editedName === undefined &&
      rowData.subjectName.startsWith("button")
    )
      return;

    return (
      rowData.isEditing === undefined && (
        <Button
          icon='pi pi-pencil'
          label='Edit '
          disabled={rowData.isLocked}
          className='p-button-raised p-button-warning p-mr-2'
          onClick={() => {
            handleEditSubject(rowData);
          }}
        />
      )
    );
  };

  const checkTemplate = (rowData) => {
    if (
      rowData.isEditing === undefined &&
      rowData.editedName === undefined &&
      rowData.subjectName.startsWith("button")
    )
      return;

    return (
      <Checkbox
        disabled={rowData.isLocked}
        checked={rowData.isSelected}
        onChange={() => {
          if (
            rowData.isSelected === false &&
            (expandedRows === null || !(rowData._id in expandedRows))
          ) {
            setExpandedRows({ ...expandedRows, [rowData._id]: true });
          }
          handleCheckSubject(rowData);
        }}
      />
    );
  };

  const footerTemplate = () => {
    return <td className='p-d-none bg-manilla' colSpan='100%'></td>;
  };

  const handleRowToggle = (e) => {
    setExpandedRows(e.data);
  };

  const rowExpansionTemplate = (data) => (
    <ContractOption
      parent={data._id}
      handlers={optionHandlers}
      options={data.options}
    />
  );

  return (
    <Card
      title={category.categoryName}
      className='p-m-2 p-text-center bg-manilla'
      id='contract-subject-card'
    >
      <DataTable
        value={category.subjects}
        dataKey='_id'
        rowGroupMode='subheader'
        groupField='subcategoryName'
        rowGroupHeaderTemplate={BoldAndItalicsSubcategory}
        rowGroupFooterTemplate={footerTemplate}
        expandedRows={expandedRows}
        onRowToggle={handleRowToggle}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column style={{ width: "3%" }} className='bg-manilla' />
        <Column
          style={{ width: "10%" }}
          header='Sub Categories'
          className='bg-manilla'
        />
        <Column
          body={nameT}
          style={{ width: "57%" }}
          className='adjustable-column bg-manilla'
          header={
            <Fragment>
              <span className='bg-manilla' style={{ width: "13%" }}>
                Subjects
              </span>
              <span className='bg-manilla'>Options</span>
            </Fragment>
          }
        />
        <Column
          className='bg-manilla'
          body={editTemplate}
          style={{ width: "10%" }}
        />
        <Column
          body={checkTemplate}
          header='Select'
          className='column-header-no-pad bg-manilla'
          style={{ width: "5%" }}
        />
        <Column
          header='View/Hide Options'
          expander
          style={{ width: "10%" }}
          className='bg-manilla'
        />
        <Column style={{ width: "5%" }} className='bg-manilla' />
      </DataTable>
    </Card>
  );
};;

export default ContractSubject;
