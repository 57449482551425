const registerInputs = {
  // name: {
  //   label: "Choose a User Name",
  //   name: "name",
  //   id: "user-name",
  //   medSize: 12,
  //   required: true,
  //   minimum: 2,
  //   maximum: 99999,
  //   textError: "Please enter a User Name",
  //   validate: "text",
  // },
  email: {
    label: "Email Address",
    name: "email",
    id: "email-address",
    medSize: 12,
    required: true,
    minimum: 2,
    maximum: 99999,
    textError: "Please enter an Email Address",
    validate: "text",
  },
  password: {
    label: "Password",
    name: "password",
    id: "password-register",
    medSize: 12,
    validate: "text",
    feedback: false,
    // mediumRegex: /.{4,}/,
    // strongRegex: /.{8,}/,
  },
  passwordConfirm: {
    label: "Confirm Password",
    name: "passwordConfirm",
    id: "password-confirm",
    medSize: 12,
    validate: "text",
    feedback: false,
    // mediumRegex: /.{4,}/,
    // strongRegex: /.{8,}/,
  },
};
export default registerInputs;
