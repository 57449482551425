import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import Login from "../../components/login/login.component";
import { CONTAINER } from "../../assets/styles/containers.syles";
import { registerUserComplete } from "../../redux/user/user.actions";

const LoginPage = ({
  user,
  registerUserComplete,
  match: {
    params: { token },
  },
}) => {
  const [tokenStatus, setTokenStatus] = useState(0);

  useEffect(() => {
    if (token === undefined) return setTokenStatus(0); // Normal login
    if (token === "changed") return setTokenStatus(1); // Password was successfully reset
    if (token === "notchanged") return setTokenStatus(2); // Password was not changed
    setTokenStatus(3); // There is a token and it needs to be validated
    confirmToken();
    // eslint-disable-next-line
  }, []);

  const confirmToken = async () => {
    await registerUserComplete(token);
    setTokenStatus(0);
  };

  return (
    <Fragment>
      <div className='bg-image'>
        <div className='p-shadow-12'>
          {tokenStatus === 0 && <Login />}
          {tokenStatus === 1 && (
            <Fragment>
              <Login msg='Your password has been changed' />
            </Fragment>
          )}
          {tokenStatus === 2 && (
            <Fragment>
              <Login msg='Your password was not changed' />
            </Fragment>
          )}

          {(tokenStatus === 3) & (user.isRegistrationConfirmed === -1) && (
            <Fragment>
              <CONTAINER width='30%'>
                <div className='p-shadow-12 p-d-block p-mx-auto'>
                  <Card className='bg-manilla'>
                    <div className='p-fluid p-grid p-dir-col'>
                      <h5>Your email address could not be confirmed</h5>
                    </div>
                  </Card>
                </div>
              </CONTAINER>
            </Fragment>
          )}
          {(tokenStatus === 3) & (user.isRegistrationConfirmed === 0) && (
            <Fragment>
              <CONTAINER width='30%'>
                <div className='p-shadow-12 p-d-block p-mx-auto'>
                  <Card
                    title='Your email address is being confirmed'
                    className='bg-manilla'
                  >
                    <div className='p-d-flex p-jc-center'>
                      <ProgressSpinner />
                    </div>
                  </Card>
                </div>
              </CONTAINER>
            </Fragment>
          )}
          {(tokenStatus === 3) & (user.isRegistrationConfirmed === 1) && (
            <Fragment>
              <Login msg='Your email is confirmed. Please login below' />
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { registerUserComplete })(LoginPage);
