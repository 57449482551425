export const ContractActionTypes = {
  LOAD_CONTRACT_NAMES_PASS: "LOAD_CONTRACT_NAMES_PASS",
  LOAD_CONTRACT_NAMES_FAIL: "LOAD_CONTRACT_NAMES_FAIL",
  // LOAD_CONTRACTS_PASS: "LOAD_CONTRACTS_PASS",
  // LOAD_CONTRACTS_FAIL: "LOAD_CONTRACTS_FAIL",
  CLEAR_CONTRACT_PASS: "CLEAR_CONTRACT_PASS",
  CREATE_CONTRACT_PASS: "CREATE_CONTRACT_PASS",
  CREATE_CONTRACT_FAIL: "CREATE_CONTRACT_FAIL",
  DELETE_CONTRACT_PASS: "DELETE_CONTRACT_PASS",
  DELETE_CONTRACT_FAIL: "DELETE_CONTRACT_FAIL",
  EDIT_CONTRACT_NAME: "EDIT_CONTRACT_NAME",
  EDIT_CONTRACT_NAME_INPUT: "EDIT_CONTRACT_NAME_INPUT",
  CANCEL_CONTRACT_NAME: "CANCEL_CONTRACT_NAME",
  SAVE_CONTRACT_NAME_PASS: "SAVE_CONTRACT_NAME_PASS",
  SAVE_CONTRACT_NAME_FAIL: "SAVE_CONTRACT_NAME_FAIL",
  SAVE_CONTRACT_PASS: "SAVE_CONTRACT_PASS",
  SAVE_CONTRACT_FAIL: "SAVE_CONTRACT_FAIL",
  LATEST_CONTRACT_PASS: "LATEST_CONTRACT_PASS",
  LATEST_CONTRACT_FAIL: "LATEST_CONTRACT_FAIL",
  TEST_LOAD_CONTRACT_PASS: "TEST_LOAD_CONTRACT_PASS",
  SELECT_ALL_ITEMS_IN_CONTRACT: "SELECT_ALL_ITEMS_IN_CONTRACT",
  LOAD_CONTRACT_PASS: "LOAD_CONTRACT_PASS",
  LOAD_CONTRACT_FAIL: "LOAD_CONTRACT_FAIL",
  LOAD_GUEST_CONTRACT_PASS: "LOAD_GUEST_CONTRACT_PASS",
  LOAD_GUEST_CONTRACT_FAIL: "LOAD_GUEST_CONTRACT_FAIL",
  RESET_CONTRACT_PASS: "RESET_CONTRACT_PASS",
  RESET_CONTRACT_FAIL: "RESET_CONTRACT_FAIL",
  GET_TEMPLATE_NAMES_PASS: "GET_TEMPLATE_NAMES_PASS",
  GET_TEMPLATE_NAMES_FAIL: "GET_TEMPLATE_NAMES_FAIL",
  RESET_TEMPLATE_NAME_PASS: "RESET_TEMPLATE_NAME_PASS",
  RESET_TEMPLATE_NAME_FAIL: "RESET_TEMPLATE_NAME_FAIL",
  SET_SELECTED_CATEGORY_PASS: "SET_SELECTED_CATEGORY_PASS",
  SET_SELECTED_SUBJECT_PASS: "SET_SELECTED_SUBJECT_PASS",
  INVERT_CHECKED_SUBJECT_PASS: "INVERT_CHECKED_SUBJECT_PASS",
  INVERT_CHECKED_OPTION_PASS: "INVERT_CHECKED_OPTION_PASS",
  SET_EDIT_SUBJECT_PASS: "SET_EDIT_SUBJECT_PASS",
  SET_EDIT_SUBJECT_INPUT: "SET_EDIT_SUBJECT_INPUT",
  SET_SAVE_SUBJECT_PASS: "SET_SAVE_SUBJECT_PASS",
  SET_CANCEL_SUBJECT_PASS: "SET_CANCEL_SUBJECT_PASS",
  SET_EDIT_OPTION_PASS: "SET_EDIT_OPTION_PASS",
  SET_EDIT_OPTION_INPUT: "SET_EDIT_OPTION_INPUT",
  SET_SAVE_OPTION_PASS: "SET_SAVE_OPTION_PASS",
  SET_CANCEL_OPTION_PASS: "SET_CANCEL_OPTION_PASS",
  MAKE_PDF_FILE_PASS: "MAKE_PDF_FILE_PASS",
  MAKE_PDF_FILE_FAIL: "MAKE_PDF_FILE_FAIL",
  LOGOUT_UNLOAD_CONTRACTS: "LOGOUT_UNLOAD_CONTRACTS",
};
