import React, { Fragment } from "react";
import ShowContracts from "./show-contracts.component";
import ShowSubscriptions from "./show-subscriptions.component";
import ShowMessages from "./show-messages.component";
import ShowUsers from "./show-users.component";
import { TabView, TabPanel } from "primereact/tabview";

const AdminPanel = () => {
  return (
    <Fragment>
      <div className='p-text-center'>
        <h1>Admin Panel</h1>
        <div className='p-grid p-my-6 container'>
          <div className='p-col-6'>
            <TabView>
              <TabPanel header='Subscriptions'>
                <ShowSubscriptions />
              </TabPanel>
              <TabPanel header='Messages'>
                <ShowMessages />
              </TabPanel>
            </TabView>
          </div>
          <div className='p-col-6'>
            <TabView>
              <TabPanel header='Users'>
                <ShowUsers />
              </TabPanel>
              <TabPanel header='Templates'>
                <ShowContracts />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminPanel;
