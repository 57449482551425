import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import axios from "axios";
import AddContract from "./add-contract.component";

const ShowContracts = ({ admin }) => {
  const [masters, setMasters] = useState([]);
  useEffect(() => {
    getAllMasters();
  }, []);
  useEffect(() => {
    getAllMasters();
  }, [admin.isNewMasterUploaded]);

  const getAllMasters = async (e) => {
    try {
      const res = await axios.get("/api/v1/masters", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      let results = res.data.data.filter(
        (con) => con.contractName !== "Free Trial Agreement"
      );
      setMasters(results);
    } catch (err) {}
  };

  const dateTemplate = (rowData) => {
    let [year, month, day] = rowData.createdAt.split("-");
    return `${month}/${day.split("T")[0]}/${year}`;
  };

  const header = (
    <div className='p-d-flex p-jc-between'>
      <p className='m-0 fontl'>Templates</p>
      <AddContract />
    </div>
  );

  return (
    <Fragment>
      <div className='p-shadow-12'>
        <Card className='small-table'>
          <DataTable value={masters} dataKey='_id' header={header}>
            {/* <Column field='revision' header='Revision' sortable={true} /> */}
            <Column
              field='templateName'
              header='Template Name'
              sortable={true}
              style={{ width: "50%" }}
            />
            <Column
              field='createdAt'
              body={dateTemplate}
              header='Uploaded Date'
              sortable={true}
              style={{ width: "50%" }}
            />
            {/* <Column field='_id' header='Database ID' sortable={true} /> */}
          </DataTable>
        </Card>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  admin: state.admin,
});
export default connect(mapStateToProps, null)(ShowContracts);
