import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import axios from "axios";
import RegisterUser from "./register-user.component";
import { validateInputs } from "./register-page.helpers";
import { clearErrors } from "../../redux/user/user.actions";
import { CONTAINER } from "../../assets/styles/containers.syles";

const RegisterPage = ({
  history,
  user: { isRegistrationSent },
  clearErrors,
}) => {
  const [userIn, setUserIn] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    emailValid: true,
    passwordValid: true,
  });
  const [loading, setLoading] = useState(false);
  const [registerFail, setRegisterFail] = useState("");
  const { email, password, passwordConfirm } = userIn;

  useEffect(() => {
    //  initialize();
    clearErrors();
    // eslint-disable-next-line
  }, []);

  const handleRegister = async (e) => {
    let { emailValid, passwordValid, overallValid } = validateInputs(userIn);
    setUserIn({ ...userIn, emailValid, passwordValid });
    if (!overallValid) return;
    let userItem = { email, password, passwordConfirm };
    try {
      setLoading(true);
      const res = await axios.post("/api/v1/bookings/signup", userItem, {
        headers: { "Content-Type": "application/json" },
      });
      setLoading(false);
      if (res.data.bypass !== undefined) setRegisterFail(res.data.bypass);
      else window.location.href = res.data.session.url;
    } catch (err) {}
  };

  const handleInputChange = (e) => {
    setUserIn({ ...userIn, [e.target.name]: e.target.value });
  };

  const goLoginUrl = () => {
    history.push("/login");
  };

  return (
    <div className='bg-image'>
      <div className='p-shadow-12'>
        <CONTAINER width='30%'>
          <div className='p-shadow-12 p-d-block p-mx-auto'>
            <Card title='Register' className='bg-manilla'>
              <div className='p-fluid p-dir-col'>
                <RegisterUser
                  goLoginUrl={goLoginUrl}
                  handleRegister={handleRegister}
                  handleInputChange={handleInputChange}
                  userIn={userIn}
                  isRegistrationSent={isRegistrationSent}
                  loading={loading}
                  registerFail={registerFail}
                />
              </div>
            </Card>
          </div>
        </CONTAINER>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    clearErrors,
  })(RegisterPage)
);
