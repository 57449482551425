import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const AdminRoute = ({ user: { role }, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        role !== "admin" ? <Redirect to='/' /> : <Component {...props} />
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(AdminRoute);
