import uuid from "react-uuid";

const findItemById = (items, id) => {
  return items.find((item) => item._id === id);
};
const findIndexById = (items, id) => {
  return items.findIndex((item) => item._id === id);
};

const findSubject = (state, id) => {
  let cat = state.selectedCategory;
  let subItem = findItemById(state.contract.categories[cat].subjects, id);
  let sub = findIndexById(state.contract.categories[cat].subjects, id);
  return { cat, sub, subItem };
};

class SubjectHelper {
  static invertedCheckedSubject = (state, id) => {
    let { cat, sub, subItem } = findSubject(state, id);
    const res = { ...state };

    res["contract"].categories[cat].subjects[sub].isSelected =
      !subItem.isSelected;
    res["isContractEdited"] = true;
    return { ...res };
  };

  static editSubject = (state, id) => {
    let { cat, sub, subItem } = findSubject(state, id);
    const res = { ...state };
    res["contract"].categories[cat].subjects[sub]["isEditing"] = true;
    // res["contract"].categories[cat].subjects[sub]["editedTempName"] =
    //   subItem["isSaved"] === true ? subItem.editedName : subItem.subjectName;
    res["contract"].categories[cat].subjects[sub]["editedTempName"] =
      res["contract"].categories[cat].subjects[sub]["editedName"] !== undefined
        ? subItem.editedName
        : subItem.subjectName;
    if (
      res["contract"].categories[cat].subjects[sub][
        "editedTempName"
      ].startsWith("button")
    )
      res["contract"].categories[cat].subjects[sub]["editedTempName"] = "";
    return { ...res };
  };

  static saveSubject = (state, id) => {
    let { cat, sub, subItem } = findSubject(state, id);
    const res = { ...state };

    // res["contract"].categories[cat].subjects[sub]["isSaved"] = true;
    res["contract"].categories[cat].subjects[sub]["isSelected"] = true;
    res["contract"].categories[cat].subjects[sub]["editedName"] =
      subItem["editedTempName"];
    delete res["contract"].categories[cat].subjects[sub]["isEditing"];
    delete res["contract"].categories[cat].subjects[sub]["editedTempName"];
    res["isContractEdited"] = true;

    if (
      res["contract"].categories[cat].subjects[sub]["subjectName"].startsWith("button")
    ) {
      res["contract"].categories[cat].subjects[sub]["subjectName"] ="User added";

      res["contract"].categories[cat].subjects[sub]["options"]=[{optionName: "button",
      isSelected: false,_id:  uuid()}]
      res["contract"].categories[cat].subjects.splice(sub+1,0,{
        subjectName: "button",
        subcategoryName: res["contract"].categories[cat].subjects[sub]["subcategoryName"],
        isSelected: false,
        options:[],
        _id:  uuid(),
      });
    }


    return { ...res };
  };

  static cancelSubject = (state, id) => {
    let { cat, sub } = findSubject(state, id);
    const res = { ...state };

    delete res["contract"].categories[cat].subjects[sub]["isEditing"];
    delete res["contract"].categories[cat].subjects[sub]["editedTempName"];
    return { ...res };
  };

  static editSubjectInput = (state, pay) => {
    let { cat, sub } = findSubject(state, pay.id);
    const res = { ...state };

    res["contract"].categories[cat].subjects[sub].editedTempName =
      pay.editedTempName;
    return { ...res };
  };
}

export default SubjectHelper;
