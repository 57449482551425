import React, { Fragment } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";

import { Markup } from "interweave";

import { Colors } from "../../assets/data/colors";

const footer = ({ parent, rowData, handleCancel, handleSave }) => {
  return (
    <div>
      <Button
        label='Save'
        icon='pi pi-check'
        onClick={() => {
          handleSave({ rowData: rowData, parent: parent });
        }}
      />
      <Button
        label='Cancel'
        icon='pi pi-times'
        onClick={() => {
          handleCancel({ rowData: rowData, parent: parent });
        }}
      />
    </div>
  );
};

const EditorTemplate = ({
  parent,
  rowData,
  field,
  header,
  handleEditInput,
  handleSave,
  handleCancel,
}) => {
  let viewName = rowData[field] !== undefined ? rowData[field] : rowData._id;
  if (rowData.editedName !== undefined) viewName = rowData.editedName;
  if (!rowData.isEditing) {
    let b = rowData.bold;
    let i = rowData.italics;
    if (b && i) viewName = `<b><i>${viewName}</i></b>`;
    if (b && !i) viewName = `<b>${viewName}</b>`;
    if (!b && i) viewName = `<i>${viewName}</i>`;

    return (
      <Fragment>
        <Markup content={viewName} allowList={["span", "b", "i"]} />
      </Fragment>
    );
  }
  let len = 50;

  const renderHeader = () => {
    return (
      <Fragment>
        <button className='ql-color no-svg bg-color-1' value={Colors.color1} />
        <button className='ql-color no-svg bg-color-2' value={Colors.color2} />
        <button className='ql-color no-svg bg-color-3' value={Colors.color3} />
        <button className='ql-color no-svg bg-color-4' value={Colors.color4} />
        <button className='ql-color no-svg bg-color-5' value={Colors.color5} />
      </Fragment>
    );
  };

  const colorHeader = renderHeader();

  return (
    <Fragment>
      {viewName}
      <Dialog
        visible={rowData.isEditing}
        header={header}
        closable={false}
        footer={() =>
          footer({
            rowData: rowData,
            handleSave: handleSave,
            handleCancel: handleCancel,
            parent: parent,
          })
        }
        onHide={() => {}}
        style={{ width: `${len}vw` }}
      >
        <div className='p-fluid'>
          <Editor
            headerTemplate={colorHeader}
            style={{ height: "120px" }}
            value={rowData.editedTempName}
            onTextChange={(e) => {
              handleEditInput({
                val: e.htmlValue,
                rowData: rowData,
                parent: parent,
              });
            }}
          />
        </div>
      </Dialog>
    </Fragment>
  );
};

export default EditorTemplate;
