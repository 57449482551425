import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

import {
  uploadMasterContract,
  makeMasterContractReady,
} from "../../redux/admin/admin.actions";

const AddContract = ({ makeMasterContractReady, uploadMasterContract }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, []);

  const initPage = async () => {
    await makeMasterContractReady();
  };

  const handleShowDialog = (e) => {
    setShowDialog(true);
  };
  const hideDialog = () => {
    setShowDialog(false);
  };

  const handleUpload = async (id) => {
    setSpinner(true);
    let form = new FormData();
    form.append("text", id.files[0]);
    await uploadMasterContract(form);
    setSpinner(false);
    setShowDialog(false);
  };

  return (
    <Fragment>
      <Dialog
        header='Upload a Master Contract'
        footer=''
        visible={showDialog}
        style={{ width: "30vw" }}
        onHide={hideDialog}
        baseZIndex={1000}
      >
        <Fragment>
          {spinner === true ? <ProgressSpinner /> : <h6>Upload</h6>}
          <FileUpload
            name='file-upload'
            id='file-upload'
            uploadHandler={handleUpload}
            customUpload
            multiple={false}
            auto
            // accept='txt/*'
            maxFileSize={1000000}
            chooseLabel='Load Master Contract File'
            emptyTemplate={
              <p className='p-m-0'>
                Drag and drop the CSV file here to upload.
              </p>
            }
          />
        </Fragment>
      </Dialog>
      <Button onClick={handleShowDialog} label='Upload New Master Contract' />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, {
  makeMasterContractReady,
  uploadMasterContract,
})(AddContract);
