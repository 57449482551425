import React, { Fragment } from "react";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Password } from "primereact/password";

const RegisterUser = ({
  handleRegister,
  goLoginUrl,
  handleInputChange,
  userIn,
  isRegistrationSent,
  loading,
  registerFail,
}) => {
  const { email, password, passwordConfirm } = userIn;
  return (
    <Fragment>
      <div className='p-grid'>
        <div className='p-col-12 p-d-block p-mx-auto'>
          <div className='p-fluid p-grid p-dir-col'>
            <div className='p-field p-col-12'>
              <label htmlFor='email-address'>Email Address</label>
              <InputText
                id='email-address'
                name='email'
                value={email}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
            <div className='p-field p-col-12'>
              <label htmlFor='password'>Password</label>
              <Password
                id='password'
                name='password'
                value={password}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
            <div className='p-field p-col-12'>
              <label htmlFor='password-confirm'>Confirm Password</label>
              <Password
                id='passwordConfirm'
                name='passwordConfirm'
                value={passwordConfirm}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
            <div className='p-d-flex p-jc-evenly'>
              {isRegistrationSent === 1 ? (
                <Message
                  severity='success'
                  text='A confirmation message was sent to your email. Please click the link in your email to confirm.'
                />
              ) : (
                <Button
                  label='Register'
                  loadingIcon='pi pi-spin pi-sun'
                  onClick={handleRegister}
                  loading={loading}
                />
              )}
            </div>
            {registerFail !== "" && (
              <Message severity='error' text={registerFail} />
            )}
            {userIn.nameValid === false && (
              <Message
                severity='error'
                text='Please make sure that your name is at least two characters'
              />
            )}
            {userIn.planValid === false && (
              <Message severity='error' text='Please select a plan' />
            )}
            {userIn.emailValid === false && (
              <Message
                severity='error'
                text='Please use a valid email address'
              />
            )}
            {userIn.passwordValid === false && (
              <Message
                severity='error'
                text='Please make sure that the Password and Confirm Password match and are at least eight characters'
              />
            )}

            {isRegistrationSent === -1 && (
              <Message
                severity='error'
                text='An error occurred, please try a different email'
              />
            )}
            <Button
              label='I Need to Login'
              className='p-button-text p-button-plain p-mt-3'
              onClick={goLoginUrl}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterUser;
