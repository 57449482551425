import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import EditorTemplate from "../templates/EditorTemplate";

const ContractOption = ({ parent, options, handlers }) => {
  const {
    handleCheckOption,
    handleEditOption,
    handleEditOptionInput,
    handleSaveOption,
    handleCancelOption,
  } = handlers;

  const nameT = (rowData) => {
    if (
      rowData.optionName.startsWith("button") &&
      rowData.isEditing === undefined &&
      rowData.editedName === undefined
    )
      return (
        <Button
          label='Add New Option'
          className='p-button-raised'
          onClick={() => {
            handleEditOption({ rowData: rowData, parent: parent });
          }}
        />
      );

    return (
      <EditorTemplate
        parent={parent}
        rowData={rowData}
        field='optionName'
        header='Edit the Option Name'
        handleEditInput={handleEditOptionInput}
        handleSave={handleSaveOption}
        handleCancel={handleCancelOption}
      />
    );
  };
  const editTemplate = (rowData) => {
    if (
      rowData.isEditing === undefined &&
      rowData.editedName === undefined &&
      rowData.optionName.startsWith("button")
    )
      return;

    return (
      <Button
        icon='pi pi-pencil'
        label='Edit '
        className='p-button-raised p-button-warning p-mr-2'
        onClick={() => {
          handleEditOption({ rowData: rowData, parent: parent });
        }}
      />
    );
  };

  const selectTemplate = (rowData) => {
    if (
      rowData.optionName.startsWith("button") &&
      rowData.editedName === undefined
    )
      return;
    return (
      <Checkbox
        checked={rowData.isSelected}
        onChange={() => {
          handleCheckOption({ rowData: rowData, parent: parent });
        }}
      />
    );
  };

  return options.length > 0 ? (
    <Fragment>
      <DataTable value={options} dataKey='id' className='no-head'>
        <Column style={{ width: "21%" }} className='bg-manilla' />
        <Column
          body={nameT}
          field='optionName'
          style={{ width: "49%" }}
          className='bg-manilla'
        />
        <Column
          className='bg-manilla'
          body={editTemplate}
          style={{ width: "10%" }}
        />
        <Column
          className='bg-manilla'
          body={selectTemplate}
          style={{ width: "5%" }}
        />
        <Column className='bg-manilla' style={{ width: "15%" }} />
      </DataTable>
      <div className='h-p67r' />
    </Fragment>
  ) : (
    <h6 className='marg-l-21pct'>There are no options for this subject</h6>
  );
};

export default ContractOption;
