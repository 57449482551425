import React, { Fragment } from "react";
import Contact from "../../components/contact/contact.component";

const ContactPage = () => {
  return (
    <Fragment>
      <div className='bg-image'>
        <div className='p-shadow-12'>
          <Contact />
        </div>
      </div>
    </Fragment>
  );
};

export default ContactPage;
