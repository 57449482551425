import { ContractActionTypes } from "./contract.types";
import ContractHelper from "./contract-helper.reducer.js";
import SubjectHelper from "./subject-helper.reducer.js";
import OptionHelper from "./option-helper.reducer.js";
import TestHelper from "./test-helper.reducer.js";

const INITIAL_STATE = {
  isSubscriber: false,
  isContractEdited: false,
  contractNames: [],
  templateNames: [],
  contract: null,
  selectedContract: null,
  selectedCategory: -1,
  pdfFileInfo: null,
  revision: 0,
};

const contractReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContractActionTypes.CLEAR_CONTRACT_PASS:
      return {
        isContractEdited: false,
        contractNames: [],
        contract: null,
        selectedContract: null,
        selectedCategory: -1,
        pdfFileInfo: null,
      };
    case ContractActionTypes.RESET_TEMPLATE_NAME_PASS:
    case ContractActionTypes.RESET_TEMPLATE_NAME_FAIL:
      return {
        ...state,
      };
    case ContractActionTypes.GET_TEMPLATE_NAMES_PASS:
      return {
        ...state,
        templateNames: action.payload,
      };
    case ContractActionTypes.LOAD_CONTRACT_PASS:
      return {
        ...state,
        contract: action.payload.converted,
        selectedContract: action.payload.converted._id,
        isContractEdited: false,
        selectedCategory: -1,
        isSubscriber: action.payload.auth,
        pdfFileInfo: null,
      };
    case ContractActionTypes.LOAD_GUEST_CONTRACT_PASS:
      return {
        ...state,
        contract: action.payload,
        selectedContract: action.payload._id,
        isContractEdited: false,
        selectedCategory: -1,
        isSubscriber: false,
        pdfFileInfo: null,
      };
    case ContractActionTypes.RESET_CONTRACT_PASS:
    case ContractActionTypes.SAVE_CONTRACT_NAME_PASS:
      return {
        ...state,
        contract: null,
        contractNames: action.payload,
        isContractEdited: false,
        selectedCategory: -1,
      };
    case ContractActionTypes.LATEST_CONTRACT_PASS:
    case ContractActionTypes.LOAD_CONTRACT_NAMES_PASS:
      return {
        ...state,
        contractNames: action.payload.data,
        revision: action.payload.count,
        isContractEdited: false,
        selectedCategory: -1,
      };
    case ContractActionTypes.CREATE_CONTRACT_PASS:
      return {
        ...state,
        contractNames: action.payload,
        isContractEdited: false,
      };
    case ContractActionTypes.SAVE_CONTRACT_PASS:
      return {
        ...state,
        isContractEdited: false,
      };

    case ContractActionTypes.DELETE_CONTRACT_PASS:
      return {
        ...state,
        contracts: [
          ...state.contracts.filter((con) => con._id !== action.payload.id),
        ],
      };
    case ContractActionTypes.SELECT_ALL_ITEMS_IN_CONTRACT:
      return TestHelper.selectAllItems(state);
    case ContractActionTypes.EDIT_CONTRACT_NAME:
      return ContractHelper.editContractName(state, action.payload);
    case ContractActionTypes.EDIT_CONTRACT_NAME_INPUT:
      return ContractHelper.editContractNameInput(state, action.payload);
    case ContractActionTypes.CANCEL_CONTRACT_NAME:
      return ContractHelper.cancelContractName(state, action.payload);
    case ContractActionTypes.SET_SELECTED_CATEGORY_PASS:
      return ContractHelper.selectedCategory(state, action.payload);

    case ContractActionTypes.INVERT_CHECKED_SUBJECT_PASS:
      return SubjectHelper.invertedCheckedSubject(state, action.payload);
    case ContractActionTypes.SET_EDIT_SUBJECT_PASS:
      return SubjectHelper.editSubject(state, action.payload);
    case ContractActionTypes.SET_SAVE_SUBJECT_PASS:
      return SubjectHelper.saveSubject(state, action.payload);
    case ContractActionTypes.SET_CANCEL_SUBJECT_PASS:
      return SubjectHelper.cancelSubject(state, action.payload);
    case ContractActionTypes.SET_EDIT_SUBJECT_INPUT:
      return SubjectHelper.editSubjectInput(state, action.payload);

    case ContractActionTypes.INVERT_CHECKED_OPTION_PASS:
      return OptionHelper.invertedCheckedOption(state, action.payload);
    case ContractActionTypes.SET_EDIT_OPTION_PASS:
      return OptionHelper.editOption(state, action.payload);
    case ContractActionTypes.SET_SAVE_OPTION_PASS:
      return OptionHelper.saveOption(state, action.payload);
    case ContractActionTypes.SET_CANCEL_OPTION_PASS:
      return OptionHelper.cancelOption(state, action.payload);
    case ContractActionTypes.SET_EDIT_OPTION_INPUT:
      return OptionHelper.editOptionInput(state, action.payload);

    case ContractActionTypes.CREATE_CATEGORY_PASS:
      return {
        ...state,
        category: action.payload,
      };
    case ContractActionTypes.CREATE_CATALOG_PASS:
    case ContractActionTypes.LOAD_CATALOG_PASS:
      return {
        ...state,
        displayType: "categories",
        catalog: action.payload,
      };
    case ContractActionTypes.SET_CATEGORY_PASS:
      return {
        ...state,
        displayType: action.payload.displayType,
        category: action.payload.category,
      };
    case ContractActionTypes.SET_SUBJECT_PASS:
      return {
        ...state,
        displayType: action.payload.displayType,
        subject: action.payload.subject,
      };
    case ContractActionTypes.SET_OPTION_PASS:
      return {
        ...state,
        displayType: action.payload.displayType,
      };

    case ContractActionTypes.LOGOUT_UNLOAD_CONTRACTS:
      return {
        isContractEdited: false,
        contractNames: [],
        contract: null,
        selectedContract: null,
        selectedCategory: -1,
        pdfFileInfo: null,
      };
    case ContractActionTypes.MAKE_PDF_FILE_PASS:
      return {
        ...state,
        pdfFileInfo: action.payload,
      };
    case ContractActionTypes.GET_TEMPLATE_NAMES_FAIL:
    case ContractActionTypes.SAVE_CONTRACT_FAIL:
    case ContractActionTypes.CREATE_CATALOG_FAIL:
    case ContractActionTypes.LOAD_CATALOG_FAIL:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};
export default contractReducer;
