import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  isRegistrationEmailConfirmed: 0,
  isConfirmedEmailFail: false,
  isLoggedIn: false,
  isAuthFail: false,
  email: "",
  role: "",
  subscriberStatus: "",
  isForgotPasswordSent: 0,
  isResetPasswordSent: 0,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.REGISTRATION_CONFIRM_PASS: // YES
      return {
        ...state,
        isRegistrationEmailConfirmed: 1,
      };
    case UserActionTypes.REGISTRATION_CONFIRM_FAIL: // YES
      return {
        ...state,
        isRegistrationEmailConfirmed: -1,
      };
    case UserActionTypes.LOGIN_SUCCESS: // YES
      return {
        ...state,
        isAuthFail: false,
        isConfirmedEmailFail: false,
      };
    case UserActionTypes.LOGIN_NO_EMAIL: // YES
      return {
        ...state,
        isAuthFail: false,
        isConfirmedEmailFail: true,
      };
    case UserActionTypes.LOGIN_FAIL: // YES
      return {
        ...state,
        isAuthFail: true,
      };
    case UserActionTypes.USER_LOADED: // YES
      return {
        ...state,
        isLoggedIn: true,
        isConfirmedEmailFail: false,
        isAuthFail: false,
        isRegistrationEmailConfirmed: 0,
        userName: action.payload.userName,
        email: action.payload.email,
        role: action.payload.role,
        subscriberStatus: action.payload.subscriberStatus,
        isForgotPasswordSent: 0,
        isResetPasswordSent: 0,
        //error: "",
      };
    case UserActionTypes.AUTH_ERROR: // YES
      return {
        ...state,
        isLoggedIn: false,
        isConfirmedEmailFail: false,
        isAuthFail: true,
        isRegistrationEmailConfirmed: 0,
        userName: "",
        email: "",
        role: "",
        subscriberStatus: "",
      };
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        isConfirmedEmailFail: false,
        isAuthFail: true,
        isRegistrationEmailConfirmed: 0,
        userName: "",
        email: "",
        role: "",
        subscriberStatus: "",
      };

    case UserActionTypes.FORGOT_PASSWORD_PASS:
      return {
        ...state,
        isForgotPasswordSent: action.payload,
        isResetPasswordSent: 0,
      };
    case UserActionTypes.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isForgotPasswordSent: -1,
        isResetPasswordSent: 0,
      };
    case UserActionTypes.SET_PASSWORD_PASS:
    case UserActionTypes.RESET_PASSWORD_PASS:
      return {
        ...state,
        isForgotPasswordSent: 0,
        isResetPasswordSent: 1,
      };
    case UserActionTypes.SET_PASSWORD_FAIL:
    case UserActionTypes.RESET_PASSWORD_FAIL:
      return {
        ...state,
        isForgotPasswordSent: 0,
        isResetPasswordSent: -1,
      };
    case UserActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        isLoginFail: false,
        isRegisterFail: false,
        isAuthFail: false,
        error: "",
      };
    default:
      return state;
  }
};

export default userReducer;
