export const Colors = {
  color1: "#000000",
  color2: "#c83349",
  color3: "#15b2d3",
  color4: "#248507",
  color5: "#f3872f",
};

export const RGBColors = {
  color1: "rgb(0, 0, 0)",
  color2: "rgb(200, 51, 73)",
  color3: "rgb(21, 178, 211)",
  color4: "rgb(36, 133, 7)",
  color5: "rgb(243, 135, 47)",
};
