import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import axios from "axios";

const ShowMessages = ({ admin }) => {
  const [messages, setMessages] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [show, setShow] = useState(false);
  const [viewMessage, setViewMessage] = useState(null);

  useEffect(() => {
    getAllMessages();
  }, []);

  const getAllMessages = async (e) => {
    try {
      const res = await axios.get("/api/v1/messages", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setMessages(res.data.data);
    } catch (err) {}
  };

  
  const dateTemplate = (rowData) => {
    let [d, t] = rowData.sentAt.split("T");
    let [year, month, day] = d.split("-");
    let [hour, minute, nu] = t.split(":");
    let timeOfDay = " AM";
    if (hour >= 12) timeOfDay = " PM";
    if (hour >= 13) hour -= 12;
    return `${month}/${day}/${year} at ${hour}:${minute}${timeOfDay}`;
  };

  const header = (
    <div className='p-d-flex p-jc-between'>
      <p className='m-0 fontl'>Messages</p>

      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );
  const actionButtons = (rowData) => {
    const handleView = async (e) => {
      setShow(true);
      setViewMessage(rowData);
    };
    const handleDelete = async (e) => {
      try {
        const res = await axios.delete(`/api/v1/messages/${rowData._id}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        setMessages(res.data.data);
      } catch (err) {}
    };
    return (
      <Fragment>
        <Button
          icon='pi pi-eye'
          className='p-button-raised p-button-warning p-mr-2 p-button-sm'
          onClick={handleView}
          tooltip='View Message'
          tooltipOptions={{ position: "top" }}
        />
        <Button
          icon='pi pi-trash'
          className='p-button-raised p-button-danger p-button-sm'
          onClick={handleDelete}
          tooltip='Delete Message'
          tooltipOptions={{ position: "top" }}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className='p-shadow-12'>
        <Card className='small-table'>
          <DataTable
            value={messages}
            dataKey='_id'
            className='p-y-025rem'
            paginator={messages.length > 10}
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            header={header}
            globalFilter={globalFilter}
          >
            <Column
              field='mailEmail'
              header='Email'
              sortable={true}
              style={{ width: "40%" }}
            />

            <Column
              field='sentAt'
              header='Date Sent'
              sortable={true}
              body={dateTemplate}
              style={{ width: "40%" }}
            />
            <Column body={actionButtons} style={{ width: "20%" }} />
          </DataTable>
          <Dialog
            header={"Viewing Message"}
            visible={show}
            style={{ width: "80vw" }}
            onHide={() => {
              setViewMessage(null);
              setShow(false);
            }}
            baseZIndex={1000}
          >
            {viewMessage !== null && (
              <Fragment>
                <h1>Name: {viewMessage.mailName}</h1>
                <h1>Email: {viewMessage.mailEmail}</h1>
                <h1>{viewMessage.mailMessage}</h1>
              </Fragment>
            )}
          </Dialog>
        </Card>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});
export default connect(mapStateToProps, null)(ShowMessages);
