import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import App from "./App";
//import AdminApp from "./AdminApp";
//import TestApp from "./TestApp";

import store from "./redux/store";

(async () => {
  //const { publishableKey } = await fetch("/config").then((r) => r.json());
  const publishableKey =
    "pk_test_51JD8XDCQijMgYqWfZtspX63dmCRh6HqJ8BaKommgcAN85jCAJ9DDwnPsGSroFWmBor6Vjgv3JQoK8UPDuZuXhh4d00UT11o95s";
  const stripePromise = loadStripe(publishableKey);
  ReactDOM.render(
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          {/* <TestApp /> */}
          {/* <AdminApp /> */}
        </BrowserRouter>
      </Provider>
    </Elements>,

    document.getElementById("root")
  );
})();